type FuzzySearchMatch = boolean

export interface FuzzySearchResult {
	target: string
	score: number
	matches: FuzzySearchMatch[]
}

export type FuzzySortResult<T> = (FuzzySearchResult & {
	item: T
	index: number
})[]

let SCORING = {
	NO_MATCH: 0,
	MATCH: 1,
	WORD_START: 2,
	START: 3,
}

let fuzzySearch = (value: string, target: string): FuzzySearchResult => {
	let score = SCORING.NO_MATCH
	let matches: FuzzySearchMatch[] = []

	if (value.length <= target.length) {
		let valueChars = Array.from(value.toLocaleLowerCase())
		let targetChars = Array.from(target.toLocaleLowerCase())
		let delta = SCORING.START

		outer:
		for (let valueIndex = 0, targetIndex = 0; valueIndex < valueChars.length; valueIndex++) {
			while (targetIndex < targetChars.length) {
				if (targetChars[targetIndex] === valueChars[valueIndex]) {
					matches[targetIndex] = true

					if (
						delta === SCORING.MATCH
						&& targetChars[targetIndex - 1] === " "
						&& targetChars[targetIndex] !== " "
					) {
						delta = SCORING.WORD_START
					}

					score += delta
					delta++
					targetIndex++
					continue outer
				}
				else {
					delta = SCORING.MATCH
					targetIndex++
				}
			}
			// Didn't exhaust search value.
			score = SCORING.NO_MATCH
			matches.length = 0
		}
	}

	return {
		target,
		score,
		matches,
	}
}

let fuzzyHighlight = (
	searchResult: FuzzySearchResult,
	highlighter = (match: string) => <mark>{match}</mark>,
) => {
	let { target, matches } = searchResult
	let separator = "\x00"

	let highlighted = []
	let open = false

	for (let index = 0; index < target.length; index++) {
		let char = target[index]
		let matched = matches[index]
		if (!open && matched) {
			highlighted.push(separator)
			open = true
		}
		else if (open && !matched) {
			highlighted.push(separator)
			open = false
		}
		highlighted.push(char)
	}

	if (open) {
		highlighted.push(separator)
		open = false
	}

	return (
		highlighted
			.join("")
			.split(separator)
			.map((part, index) => (index % 2 ? highlighter(part) : part))
	)
}

function fuzzySort<T>(value: string, items: T[], key?: keyof T): FuzzySortResult<T> {
	let sorted = []

	for (let index = 0; index < items.length; index++) {
		let item = items[index]
		let target = key ? item[key] : item
		let result = fuzzySearch(value, target)
		if (result.score) {
			sorted.push({ ...result, item, index })
		}
	}

	sorted.sort((a, b) => {
		let delta = b.score - a.score
		if (delta === 0) {
			delta = a.index - b.index
		}
		return delta
	})

	return sorted
}

export { fuzzyHighlight, fuzzySearch, fuzzySort }
