import { createEffect, createMemo, Match, Show, Switch, type ComponentProps } from "solid-js"

import { type User } from "@sferadel/ts-lib/entities"

import {
	useCache, signal, recompose, cl,
	type ComponentLike, type ComposableComponentProps,
	stringToColor
} from "#/lib/mod"
import { getPictureUrl } from "#/lib/media/mod"
import { OnlineStatus } from "#/proto/schema"

import { IMG_REMOVE_BORDER_SRC } from "./picture"

function NamedAvatar(props: { name: string } & ComposableComponentProps<"div">) {
	let [first_name, surname] = props.name.split(" ")

	let bg = signal(null)

	let { composed, uncomposed } = recompose(props, "name", "classList", "style")
	Promise
		.all([first_name, surname].map(n => stringToColor(n)))
		.then(([c1, c2]) => `linear-gradient(150deg, ${c1},${c2})`)
		.then(bg)
		.catch(Function.NOOP)

	return (
		<div {...composed}
			classList={{
				":c: flex-center font-bold c-white select-none": true,
				...uncomposed.classList
			}}
			style={{
				"background": bg(),
				"container-type": "inline-size",
				...uncomposed.style
			}}
		>
			<span class="text-50cqw" children={[first_name[0], surname[0]]} />
		</div>
	)
}

type UserAvatarProps = {
	user: Partial<Pick<User, "avatar_key" | "name" | "num">>
	Wrapper?: ComponentLike<"div">
	Img?: ComponentLike<"img">
} & ComposableComponentProps<"div"> & { class?: string }

export function UserAvatar(_props: UserAvatarProps) {
	let { composed, uncomposed } = recompose(_props, "user", "Wrapper", "Img", "class", "classList")

	let { Wrapper = p => <div {...p} />, Img = p => <img {...p} /> } = uncomposed

	let cache = useCache()

	let error = signal(false)

	let getOnlineStatus = createMemo(() => cache.resolve("presense_list", uncomposed.user?.num)?.status)

	createEffect(() => {
		uncomposed.user
		uncomposed.user?.name
		uncomposed.user?.avatar_key
		error(false)
	})

	let inner_class = ":c: h-full w-full object-cover rounded-inherit"
	let ImgWrapped = (p: ComponentProps<"img">) => <Img {...p} class={inner_class} onError={[error, true]} />

	return (
		<Wrapper
			{...composed}
			classList={{
				":c: relative rounded aspect-ratio-square max-w-inherit max-h-inherit select-none ptr": true,
				[uncomposed.class]: !!uncomposed.class,
				...uncomposed.classList
			}}
		>
			<Switch>
				<Match when={!uncomposed.user || !uncomposed.user.name && !uncomposed.user.avatar_key}>
					<ImgWrapped src={IMG_REMOVE_BORDER_SRC} />
				</Match>
				<Match when={uncomposed.user.name && (!uncomposed.user.avatar_key || error())}>
					<NamedAvatar name={uncomposed.user.name} classList={cl(inner_class)} />
				</Match>
				<Match when={uncomposed.user.avatar_key}>
					<ImgWrapped src={getPictureUrl(uncomposed.user.avatar_key)} />
				</Match>
			</Switch>
			<Show when={getOnlineStatus()}>
				<div class=":c: absolute rounded h-25% w-25% right-3% bottom-3% light:bg-white dark:bg-gray-900">
					<div
						classList={{
							":c: h-75% w-75% rounded abs-centered": true,
							[function() {
								switch (getOnlineStatus()) {
									case OnlineStatus.ONLINE: return "bg-green"
									case OnlineStatus.AWAY: return "i-hero:moon-solid bg-amber"
								}
							}()]: true
						}}
					/>
				</div>
			</Show>
		</Wrapper>
	)
}
