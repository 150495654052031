import { createRenderEffect, Match, Switch, type ParentProps } from "solid-js"
import { unwrap } from "solid-js/store"

import { ROUTES, useRouter, useAuth, langs, Navigate } from "#/lib/mod"

import { InfoOverlay } from "../info-overlay"

export function AuthenticationGuard(props: ParentProps) {
	let auth = useAuth()

	createRenderEffect(() => {
		if (!auth || auth.is_auth_pending) {
			console.log("[AuthenticationGuard]: Waiting for loading")
			return
		}
		if (auth.is_authenticated) {
			console.log("[AuthenticationGuard]: Sucessfully authed", unwrap(auth.user))
			return
		}
		console.log("Not authenticated!", unwrap(auth.user), auth.status)
	})

	return (
		<Switch fallback={[<NotAuthenticationMockScreen />, <Navigate to={ROUTES.login.template} />]}>
			<Match
				when={!auth || auth.is_auth_pending}
				children={<AuthenticationMockScreen />}
			/>
			<Match when={auth.is_authenticated && !auth.user.visible}>
				<Navigate to={ROUTES.onboarding.template} />
				{props.children}
			</Match>
			<Match when={auth.is_authenticated && auth.user.visible} children={props.children} />
		</Switch>
	)
}

function AuthenticationMockScreen() {
	return (
		<InfoOverlay class=":c: flex flex-col items-center dark:c-gray-200 light:c-gray-600">
			<i class=":c: i-hero:face-smile h20 w20" />
			<div class=":c: mt2 text-3xl font-medium" innerText={t().authenticating} />
		</InfoOverlay>
	)
}
function NotAuthenticationMockScreen() {
	return (
		<InfoOverlay class=":c: flex flex-col items-center dark:c-gray-200 light:c-gray-600">
			<i class=":c: i-hero:face-frown h20 w20" />
			<div class=":c: mt2 text-3xl font-medium" innerText={t().not_authenticated} />
		</InfoOverlay>
	)
}

let t = langs({
	ru: {
		not_authenticated: "Мы не знакомы 🙈",
		authenticating: "Авторизация",
	},
	en: {
		not_authenticated: "You're not authenticated 🙈",
		authenticating: "Trying to reconize you as user",
	},
	ua: {
		not_authenticated: "Ми не знаємо вас 🙈",
		authenticating: "Спробуємо розпізнати вас",
	},
})
