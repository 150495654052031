import { createEffect, createSignal, type ParentProps, untrack } from "solid-js"

type BottomSheetProps = ParentProps<{
	maxHeight?: number
	onPercent?(percent: number): void
}>
export function BottomSheet(props: BottomSheetProps) {
	let HANDLE_HEIGHT = 6
	let MIN_SHEET_HEIGHT = 24

	let [y, setY] = createSignal(MIN_SHEET_HEIGHT)
	let [maxHeight, setMaxHeight] = createSignal(props.maxHeight ?? Number.NEGATIVE_INFINITY)
	let handle: HTMLDivElement
	let containerRef: HTMLDivElement
	let pointerDown = false

	let clamp = pos => Math.min(Math.max(pos, MIN_SHEET_HEIGHT), maxHeight())

	createEffect(() => {
		if (props.maxHeight) {
			return
		}
		props.children
		let { height } = containerRef.getBoundingClientRect()
		setMaxHeight(height)
		untrack(() => setY(clamp(y)))
	})
	createEffect(() => {
		props.onPercent?.((y() - MIN_SHEET_HEIGHT) / (maxHeight() - MIN_SHEET_HEIGHT))
	})
	return (
		<div
			fixed
			w100svw
			max-w-96
			flex
			flex-col
			items-center
			rounded-t-16px
			z-10
			style={{
				transform: `translateY(-${y()}px)`,
			}}
			ref={r => containerRef = r}
			{...props}
		>
			<div
				bg-gray-600
				w36px
				will-change-transform
				rounded-8px
				ptr
				ref={r => handle = r}
				onClick={e => {
					console.log(y(), maxHeight())
					if (y() === maxHeight()) {
						setY(MIN_SHEET_HEIGHT)
					}
					else {
						setY(maxHeight())
					}
				}}
				onPointerDown={e => {
					pointerDown = true
				}}
				onPointerMove={e => {
					if (!pointerDown) {
						return
					}
					let pos = window.innerHeight - e.clientY + HANDLE_HEIGHT / 2
					setY(clamp(pos))
					e.preventDefault()
					e.stopImmediatePropagation()
				}}
				onPointerUp={e => {
					if (!pointerDown) {
						return
					}
					pointerDown = false
					let TRANSITION_DURATION_MS = 200
					containerRef.style.transition = `transform ${TRANSITION_DURATION_MS}ms ease-in-out`
					setTimeout(() => {
						containerRef.style.transition = null
					}, TRANSITION_DURATION_MS)
					e.preventDefault()
					e.stopImmediatePropagation()
				}}
				style={{ height: HANDLE_HEIGHT + "px" }}
			/>
			<div w-full mt8px>
				{props.children}
			</div>
		</div>
	)
}
