import { drop, type ComposableComponentProps } from "#/lib/mod"

export function ContinueButton(props: ComposableComponentProps<"button"> & { class?: string }) {
	let other = drop(props, "classList", "onContextMenu", "class")

	return (
		<button
			type="button"
			onContextMenu={e => e.preventDefault()}
			use:ripple
			{...other}
			classList={{
				":c: w-auto relative mt-auto mb-8 h-12 font-bold c-white rounded-11 shrink-0 ptr": true,
				":c: b-style-none bg-blue-450 disabled:dark:(bg-blue-900 c-blue-700) disabled:light:(bg-blue-100 c-blue-000) active:bg-blue-450/80": true,
				[props.class]: props.class != null,
				...props.classList
			}}
		/>
	)
}
