import "./theme.css"

import {
	createContext, createEffect, useContext,
	type ParentProps
} from "solid-js"

import { AppCookieKey, AppCookies, doNextFrame, signal } from "#/lib/mod"
import { Meta } from "#/lib/meta"

export enum Theme {
	Light = "light",
	Dark = "dark",
}
function isSupportedTheme(theme: string): theme is Theme {
	return Object.values(Theme).includes(theme as Theme)
}

let ThemeContext = createContext<ReturnType<typeof ThemeContextProvider>["ctx"]>()

const TRANSIT_DURATION_MS = 100
// let propsToTranist = ["background-color", "color", "fill", "stroke"]
// let style = propsToTranist.map(prop => `${prop} ${TRANSIT_DURATION_MS}ms ease`).join(",")
const SWITCH_THEME_CLASS = "switch-theme"

export function ThemeContextProvider(props: ParentProps) {
	let root = document.documentElement
	let prefer_dark_query = matchMedia("(prefers-color-scheme: dark)")

	let actual_theme: Theme | null = null
	let switch_timeout = Number.NEGATIVE_INFINITY

	let getRootBgColor = () => getComputedStyle(root).getPropertyValue("background-color")

	function getInitialTheme() {
		let theme = AppCookies.get(AppCookieKey.Theme) as Theme
		if (!isSupportedTheme(theme)) {
			theme = prefer_dark_query.matches ? Theme.Dark : Theme.Light
		}
		return theme
	}

	let theme = signal(getInitialTheme())
	let transit = signal(false)

	applyTheme(theme())

	function transitTheme(to: Theme) {
		if (switch_timeout) {
			clearTimeout(switch_timeout)
		}
		transit(true)
		root.classList.add("switch-theme")

		doNextFrame(() => {
			applyTheme(to)
			switch_timeout = window.setTimeout(() => {
				root.classList.remove("switch-theme")
				theme(to)
				transit(false)
			}, TRANSIT_DURATION_MS)
		})
	}

	function applyTheme(theme: Theme) {
		if (actual_theme) {
			root.classList.replace(actual_theme, theme)
		}
		else {
			root.classList.add(theme)
		}
		if (actual_theme) {
			AppCookies.set(AppCookieKey.Theme, theme)
		}
		actual_theme = theme
	}

	prefer_dark_query.onchange = event => {
		let wish_theme = event.matches ? Theme.Dark : Theme.Light
		transitTheme(wish_theme)
		theme(wish_theme)
	}

	createEffect(() => {
		if (transit() && !root.classList.contains(SWITCH_THEME_CLASS)) {
			root.classList.add(SWITCH_THEME_CLASS)
		}
		else {
			root.classList.remove(SWITCH_THEME_CLASS)
		}
	})

	let ctx = { theme, transitTheme }

	return (
		Object.assign(<ThemeContext.Provider
			value={ctx}
			children={[
				<Meta name="theme-color" content={getRootBgColor()} />,
				props.children,
			]}
		/>, { ctx })
	)
}

export let useTheme = () => useContext(ThemeContext)
