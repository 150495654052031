import { createContext, useContext } from "solid-js"
import { createMutable } from "solid-js/store"

export let PageContext = createContext<ReturnType<typeof createPageContext>>()
export let usePage = () => useContext(PageContext)

export function createPageContext() {
	let ctx = createMutable({
		// /** lvl 1 */
		// root: null as HTMLElement,
		// /** lvl 2 */
		// cols: null as HTMLElement,
		// /** lvl 3 */
		// col_main: null as HTMLElement,


		modal_root: null as HTMLElement,
		scrolling_root: null as HTMLElement,
	})
	return ctx
}
