export function renderWaveform1(
	ctx: CanvasRenderingContext2D,
	stream: MediaStream,
	autdio_context = new AudioContext(),
) {
	let source = autdio_context.createMediaStreamSource(stream)

	let analyser = autdio_context.createAnalyser()
	analyser.fftSize = 2048

	let bufferLength = analyser.frequencyBinCount

	let data = new Uint8Array(bufferLength)

	source.connect(analyser)

	let draw_raf: number
	function draw() {
		let width = ctx.canvas.width
		let height = ctx.canvas.height

		draw_raf = requestAnimationFrame(draw)

		analyser.getByteTimeDomainData(data)

		ctx.clearRect(0, 0, width, height)

		ctx.lineWidth = 2
		ctx.strokeStyle = "rgb(255, 255, 255)"

		ctx.beginPath()

		let sliceWidth = (width * 1.0) / bufferLength
		let x = 0

		for (let i = 0; i < bufferLength; i++) {
			let v = data[i] / 128.0
			let y = (v * height) / 2

			if (i === 0) {
				ctx.moveTo(x, y)
			}
			else {
				ctx.lineTo(x, y)
			}

			x += sliceWidth
		}

		ctx.lineTo(ctx.canvas.width, ctx.canvas.height / 2)
		ctx.stroke()
	}

	draw()

	return () => cancelAnimationFrame(draw_raf)
}

let SPIKE_WIDTH = 2
let SPIKE_STEP = 4
let SPIKE_RADIUS = 1
let HEIGHT = 23

export function renderWaveform2(
	canvas: HTMLCanvasElement,
	spikes: ArrayLike<number>,
	peak: number,
	progress: number = 1,
) {
	let width = spikes.length * SPIKE_STEP
	let height = HEIGHT

	canvas.width = width * 2
	canvas.height = height * 2
	canvas.style.width = width + "px"
	canvas.style.height = height + "px"

	let ctx = canvas.getContext("2d")
	ctx.scale(2, 2)

	for (let i = 0; i < spikes.length; i++) {
		let item = spikes[i]

		ctx.globalAlpha = i / spikes.length >= progress ? 0.5 : 1
		ctx.fillStyle = progress > i / spikes.length ? "#FFFFFF" : "#494A78"
		let spike_height = Math.max(2, HEIGHT * (item / Math.max(1, peak)))
		roundedRectangle(ctx, i * SPIKE_STEP, (height + spike_height) / 2, SPIKE_WIDTH, spike_height, SPIKE_RADIUS)
		ctx.fill()
	}
}

function roundedRectangle(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number,
) {
	if (width < 2 * radius) {
		radius = width / 2
	}
	if (height < 2 * radius) {
		radius = height / 2
	}

	ctx.beginPath()
	ctx.moveTo(x + radius, y)
	ctx.arcTo(x + width, y, x + width, y - height, radius)
	ctx.arcTo(x + width, y - height, x, y - height, radius)
	ctx.arcTo(x, y - height, x, y, radius)
	ctx.arcTo(x, y, x + width, y, radius)
	ctx.closePath()
}
