import { type ComponentProps } from "solid-js"

import { recompose, type ComponentLike, type ComposableComponentProps } from "#/lib/mod"
import { CommonModalWrapper } from "#/lib/overlay"

// fixed z102 rounded-3 p1 shadow-xl backdrop-blur-[10px] dark:bg-gray-800/70 light:bg-gray-000/7
export function Menu(props: ComposableComponentProps<"div"> & { class?: string }) {
	let { composed, uncomposed } = recompose(props, "class", "classList", "onClick")
	return (
		<CommonModalWrapper
			onClick={e => {
				e.stopPropagation()
				uncomposed.onClick instanceof Function ? uncomposed.onClick?.(e) : null
			}}
			{...composed}
			classList={{
				":c: flex flex-col gap-1 min-w-40 p-1 rounded-3 dark:bg-gray-800 light:bg-gray-000": true,
				[uncomposed.class]: !!uncomposed.class,
				...uncomposed.classList
			}}

		/>
	)
}

Menu.MenuItem = function(
	props: ComponentProps<"div"> & {
		Container?: ComponentLike<"div">
		Icon?: ComponentLike<"i">
		icon_class?: string
		Text?: ComponentLike<"div">
		text?: string
		text_class?: string
	},
) {
	let { composed, uncomposed } = recompose(
		props,
		"Container",
		"Icon",
		"icon_class",
		"Text",
		"text",
		"text_class",
		"class",
		"classList"
	)
	let {
		Container = p => <div {...p} />,
		Icon = p => <i {...p} />,
		Text = p => <div {...p} />,
	} = uncomposed

	return (
		<Container
			{...composed}
			classList={{
				":c: hover:(bg-gray-100 dark:bg-gray-700) rounded-2 pl-1.5 pr-2 p-block-1 flex items-center select-none ptr": true,
				[uncomposed.class]: !!uncomposed.class,
				...uncomposed.classList
			}}
		>
			<Icon classList={{ [uncomposed.icon_class]: !!uncomposed.icon_class }} />
			<Text
				innerText={uncomposed.text}
				class="fw-medium ml1"
				classList={{ [uncomposed.text_class]: !!uncomposed.text_class }}
			/>
		</Container>
	)
}
