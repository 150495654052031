/* @refresh reload */

import "uno.css"
import "#/assets/styles/index.css"
import "#/assets/styles/fonts.css"

import "./lib/polyifills"
import "./lib/globals"
import "./lib/std"

import { lazy, Match, Switch } from "solid-js"
import { render } from "solid-js/web"
import { Toaster } from "solid-toast"

import { env, useLayout } from "#/lib/mod"
import { MetaProvider, Title } from "#/lib/meta"
import { ThemeContextProvider } from "#/lib/appearance/theme"
import { AuthContextProvider } from "#/lib/auth"
import { I18n } from "#/lib/appearance/i18n"
import { registerWorker } from "#/worker/mod"

// Contexts
import { LayoutContextProvider } from "#/layout.context"
import { CacheContextProvider } from "#/lib/cache/cache.context"
import { RpcContextProvider } from "#/lib/remote/rpc.context"
import { DealsContextProvider } from "#/domains/deals/deals.context"
import { MessagesContextProvider } from "#/domains/chats/chats.context"

import { MOBILE_NAVBAR_HEIGHT } from "./common/mod"

if ("serviceWorker" in navigator) {
	// let { default: sw_url } = await import("#/sw.ts?url") // vite places sw to assets and breaks it
	let sw_url = "/sw." + (import.meta.env.DEV ? "ts" : "js")
	navigator.serviceWorker.register(sw_url, { type: "module", scope: "/" })
}
registerWorker(new Worker("/worker." + (import.meta.env.DEV ? "ts" : "js"), { type: "module", name: "sferadel-worker" }))

console.log("environment", env)
render(App, document.body)

function Navigator() {
	let app = useLayout()

	let MobileNavigator = lazy(() => import("./common/layout/navigator.mobile"))
	let DesktopNavigator = lazy(() => import("./common/layout/navigator.desktop"))

	return <Switch>
		<Match when={/*@once*/ app.is_mobile}>
			<MobileNavigator />
		</Match>
		<Match when={/*@once*/ app.is_desktop}>
			<DesktopNavigator />
		</Match>
	</Switch>
}

function App() {
	return [
		// if problem with cycling dependencies would appear, consider to use single context that will hold
		// event emitters / channels for each context. it should be called actor model idk
		<MetaProvider>
			<Title innerText={import.meta.env.DEV ? "[local] FAUNDR" : "FAUNDR"} />
			<I18n>
				<LayoutContextProvider>
					<ThemeContextProvider>
						<CacheContextProvider>
							<AuthContextProvider>
								<RpcContextProvider>
									<DealsContextProvider>
										<MessagesContextProvider>
											<Navigator />
										</MessagesContextProvider>
									</DealsContextProvider>
								</RpcContextProvider>
							</AuthContextProvider>
						</CacheContextProvider>
					</ThemeContextProvider>
				</LayoutContextProvider>
			</I18n>
		</MetaProvider>,
		<Toaster
			toastOptions={{
				position: "bottom-center",
				duration: 1550
			}}
			containerStyle={{
				bottom: `calc(10px + ${MOBILE_NAVBAR_HEIGHT})`,
			}}
		/>,
	]
}
