import type { useLayout } from "#/lib/mod"

type RouteFn<T extends string | number> = (...args: (T)[]) => string

export type RouteDefinition<
	R extends string | number = string | number,
	T extends string = string,
	Fn extends RouteFn<R> = RouteFn<R>
> = {
	"template": T
	"resolve"?: Fn
	"interactive-widget"?: ReturnType<typeof useLayout>["meta"]["viewport"]["interactive-widget"]
}

// function createRouteDefinition<T extends string, Fn extends RouteFn>(opts: RouteDefinition<T, Fn>) {
// 	return opts
// }

export let ROUTES = {
	login: {
		template: "/login",
	},
	oauth: {
		template: "/oauth",
	},
	onboarding: {
		template: "/onboarding",
	},
	profile: {
		template: "/:nickname",
		resolve: nickname => `/@${nickname}`,
	},
	friends: {
		template: "/:nickname/friends",
		resolve: nickname => `/@${nickname}/friends`,
	},
	profile_settings: {
		template: "/settings",
	},
	profile_interests: {
		template: "/interests",
	},
	notifications: {
		template: "/notifications",
	},
	deals: {
		template: "/deals",
	},
	deal: {
		template: "/deal/:num",
		resolve: num => `/deal/${num}`,
	},
	deal_new: {
		template: "/deal/new/:type",
		resolve: (type: "vacancy" | "begging" | "investment") => `/deal/new/${type}`,
	},
	deal_edit: {
		template: "/deal/:num/edit",
		resolve: (num: number) => `/deal/${num}/edit`,
	},
	deals_search: {
		template: "/deals/search",
	},
	community: {
		template: "/community",
	},
	post: {
		template: "/post/:num",
		resolve: num => `/post/${num}`,
	},
	projects: {
		template: "/projects",
	},
	project: {
		template: "/project/:num",
		resolve: num => `/project/${num}`,
	},
	project_new: {
		template: "/project/new",
	},
	project_upsert: {
		template: "/project/:num/edit",
		resolve: num => `/project/${num}/edit`,
	},
	chats: {
		template: "/chats",
	},
	chat: {
		"template": "/chat/:nickname",
		"resolve": nickname => `/chat/@${nickname}`,
		"interactive-widget": "resizes-content",
	},
	index: {
		template: "/",
	},
	all: {
		template: "/*",
	},
} as const
