import type { ComponentProps } from "solid-js"

export function FaundrLogoIcon(props: ComponentProps<"svg">) {
	return (
		<svg viewBox="0 0 170 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g>
				<path
					d="M18.9827 31.2663L30.4701 0.254761H36.4103L47.9414 31.2663H41.5207L39.3804 25.064H27.4999L25.4034 31.2663H18.9827ZM29.2471 19.8226H37.6333L33.4839 7.68007H33.3965L29.2471 19.8226Z"
				/>
				<path
					d="M62.6667 31.6594C55.1978 31.6594 50.1311 26.8985 50.1311 18.4249V0.254761H56.2897V18.0318C56.2897 22.487 58.561 25.8939 62.6667 25.8939C66.4231 25.8939 69.1311 22.9675 69.1311 18.0318V0.254761H75.2898V18.4249C75.2898 26.5054 70.6162 31.6594 62.6667 31.6594Z"

				/>
				<path
					d="M81.5767 31.2663V0.254761H87.6043L101.538 21.1767H101.625V0.254761H107.696V31.2663H101.538L87.779 10.7812H87.6917V31.2663H81.5767Z"

				/>
				<path
					d="M114.463 31.2663V0.254761H124.728C134.555 0.254761 140.845 6.63179 140.845 15.8042C140.845 24.933 134.817 31.2663 124.859 31.2663H114.463ZM120.535 25.8066H124.466C130.493 25.8066 134.424 21.4824 134.424 15.8042C134.424 9.99502 130.45 5.71454 124.815 5.71454H120.535V25.8066Z"

				/>
				<path
					d="M146.241 31.2663V0.254761H157.99C165.459 0.254761 168.779 4.62259 168.779 10.5628C168.779 15.2801 166.595 18.5123 162.315 19.91L170.089 31.2663H162.795L155.85 20.6525H152.312V31.2663H146.241ZM152.312 15.2364H156.767C160.524 15.2364 162.446 13.3146 162.446 10.4755C162.446 7.59271 160.567 5.71454 157.03 5.71454H152.312V15.2364Z"

				/>
				<path d="M0 0H20.8234V5.58676H0V0Z" />
				<path d="M0 12.6971H18.2839V18.2838H0V12.6971Z" />
				<path
					d="M5.58667 12.6971L5.58667 30.981L-8.61531e-05 30.981L-8.53539e-05 12.6971L5.58667 12.6971Z"

				/>
			</g>
		</svg>
	)
}
