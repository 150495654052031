// export { createComputed as computed, createEffect as effect, createMemo as memo } from "solid-js"
export * from "./data"
export * from "./types"

import {
	$PROXY,
	children,
	createComputed,
	createSignal,
	onCleanup,
	splitProps,
	untrack,
	type JSX,
	type Ref,
	type Setter,
} from "solid-js"
import { type Store } from "solid-js/store"

export function trackDeep<T extends Store<object>>(store: T): T {
	function traverse<T>(value: Store<T>, seen: Set<unknown>): void {
		let isArray: boolean, proto: any
		// check the same conditions as in `isWrappable` from `/packages/solid/store/src/store.ts`
		if (
			value != null && typeof value === "object" && !seen.has(value)
			&& ((isArray = Array.isArray(value))
				|| value[$PROXY]
				|| !(proto = Object.getPrototypeOf(value))
				|| proto === Object.prototype)
		) {
			seen.add(value)
			for (const child of isArray ? (value as any[]) : Object.values(value)) traverse(child, seen)
		}
	}

	traverse(store, new Set())
	return store
}

export function Ref<T>(props: { ref: (val: ReturnType<T>) => void; children: T }) {
	const cb = props.ref as (el: T | undefined) => void

	const resolved = children(() => props.children as any)

	let prev: T | undefined

	createComputed(() => {
		const el = resolved.toArray().find(item => item instanceof Element) as T
		if (el !== prev) {
			untrack(() => cb(el))
		}
		prev = el
	})

	onCleanup(() => prev && cb(undefined))

	return resolved as unknown as JSX.Element
}

function createRwSignal<T = any>(...args: Partial<Parameters<typeof createSignal<T>>>) {
	const [getter, setter] = createSignal<T>(...args)
	return <A extends Parameters<Setter<T>>>(...args: Partial<A>) => {
		if (args.length === 0) return getter()
		// @ts-ignore
		return setter(...args)
	}
}
export { createRwSignal as signal }

export function recompose<P, EK extends keyof P>(props: P, ...exclude: EK[]) {
	type PK = keyof P

	let [composed, uncomposed] = splitProps(
		props,
		(Object.keys(props) as PK[]).filter(k => !exclude.includes(k as EK)) as Exclude<PK, EK>[],
	)

	return { composed, uncomposed }
}

export function drop<P, EK extends keyof P>(props: P, ...exclude: EK[]) {
	return splitProps(props, exclude)[1]
}
