import "./ripple.css"
import { onCleanup, onMount } from "solid-js"
import { useLayout } from "#/layout.context"

let RIPPLE_DURATION_MS = 250
let RIPPLE_TIMEOUT_MS = 100

export function ripple(ref: HTMLElement, { duration = RIPPLE_DURATION_MS } = {}) {
	let layout = useLayout()
	// aware here! Solid use synthetic events by default
	// If you want to avoid bubble / cancel event from child element, consider using on:__
	// btw this is fixed by `e.target !== e.currentTarget`
	ref.addEventListener("pointerdown", onPointerDown, { passive: true })
	ref.addEventListener("pointerup", onPointerUp, { passive: true })
	ref.addEventListener("pointercancel", onPointerUp, { passive: true })
	if (layout.is_mobile) {
		window.addEventListener("touchmove", onTouchMove, { passive: true })
	}
	else if (layout.is_desktop) {
		window.addEventListener("pointermove", onTouchMove, { passive: true })
	}

	onCleanup(() => {
		ref.removeEventListener("pointerdown", onPointerDown)
		ref.removeEventListener("pointerup", onPointerUp)
		ref.removeEventListener("pointercancel", onPointerUp)
		window.removeEventListener("touchmove", onTouchMove)
	})

	let rectangleRipple = <div class="ripple wrapper" /> as HTMLDivElement
	ref.prepend(rectangleRipple)
	onMount(() => {
		rectangleRipple.style.borderRadius = getComputedStyle(ref).borderRadius
	})

	let isPointerDown = false
	let wasMoved = false
	let isDrawing = false
	let requestStop: (force?: boolean) => void
	let hold: number

	function onPointerDown(e: PointerEvent) {
		if ("noripple" in e) return
		if (document.getSelection().type === "Range") return

		isPointerDown = true
		wasMoved = false

		if (hold) {
			clearTimeout(hold)
			hold = null
		}
		let local_hold = hold = window.setTimeout(start, RIPPLE_TIMEOUT_MS)

		function start() {
			if (local_hold !== hold) return
			if (ref.hasAttribute("disabled")) return

			if (!isPointerDown || isDrawing || wasMoved) return

			draw(e.clientX, e.clientY)
		}
	}

	function onTouchMove(e: TouchEvent) {
		wasMoved = true
		if (isDrawing) {
			e.stopPropagation()
			requestStop?.()
		}
	}

	function onPointerUp(e: PointerEvent) {
		wasMoved = false
		isPointerDown = false
		requestStop?.()
	}

	function draw(x: number, y: number) {
		isDrawing = true
		let circle = <div /> as HTMLDivElement

		let startedAt = Date.now()

		let stop = function(force = false) {
			if (force === true) {
				circle?.remove()
				return
			}
			let elapsedTime = Date.now() - startedAt

			if (elapsedTime < duration) {
				let delay = Math.max(duration - elapsedTime, duration / 2)

				setTimeout(() => circle.classList.add("hiding"), Math.max(delay - duration / 2, 0))

				setTimeout(() => {
					circle.remove()
					isDrawing = false
				}, delay)
			}
			else {
				circle.classList.add("hiding")
				setTimeout(() => {
					circle.remove()
					isDrawing = false
				}, duration / 2)
			}
			requestStop = null
		}

		requestStop = stop

		requestAnimationFrame(() => {
			if (requestStop != stop) {
				return
			}

			let r = rectangleRipple.getBoundingClientRect()
			circle.classList.add("rectangle")

			let clickX = x - r.left
			let clickY = y - r.top

			let radius = Math.sqrt(
				(Math.abs(clickY - r.height / 2) + r.height / 2) ** 2
				+ (Math.abs(clickX - r.width / 2) + r.width / 2) ** 2,
			)

			let circleX = clickX - radius / 2
			let circleY = clickY - radius / 2

			circle.style.width = circle.style.height = radius + "px"
			circle.style.left = circleX + "px"
			circle.style.top = circleY + "px"

			rectangleRipple.append(circle)
		})
	}
}

export function noripple(ref: HTMLElement) {
	let ignore = (e: PointerEvent) => e["noripple"] = true

	ref.addEventListener("pointerdown", ignore)
	onCleanup(() => ref.removeEventListener("pointerdown", ignore))
}
