import { drop } from "#/lib/mod"
import { type ComponentProps, type ParentProps } from "solid-js"

export function InfoOverlay(props: ParentProps & ComponentProps<"div">) {
	let other = drop(props, "class", "classList")

	return <div {...other} classList={{
		"abs-centered max-w-inherit w-full": true,
		[props.class]: !!props.class,
		...props.classList,
	}} />
}

export let LoadingScreen = () => <InfoOverlay
	class=":c: flex flex-col items-center dark:c-gray-200 light:c-gray-600"
	children={[
		<span class="text-20" innerText="⏳" />,
		<span class="font-medium text-3xl" innerText="Загрузка" />,
	]}
/>
