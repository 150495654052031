import { For } from "solid-js"

import { recompose, type ComposableComponentProps } from "#/lib/mod"

type SegmentNavProps = {
	count: number
	step: number
	onSegmentClick?(index: number): void
} & ComposableComponentProps<"div"> & { class?: string }

export function SegmentNav(props: SegmentNavProps) {
	let { composed, uncomposed } = recompose(props, "count", "step", "onSegmentClick", "class")

	return (
		<div {...composed}
			classList={{
				"flex gap-0.5 justify-between items-center": true,
				[uncomposed.class]: !!uncomposed.class,
				...props.classList
			}}>
			<For each={new Array(uncomposed.count).fill(null)}>
				{(_, i) => (
					<div class="flex-1 h3 flex items-center" onClick={() => uncomposed.onSegmentClick?.(i())}>
						<div
							class="h-0.5 w-full rounded-12px li	ght:bg-gray-000 dark:bg-gray-600"
							classList={{
								["bg-blue-500!"]: i() === uncomposed.step,
							}}
						/>
					</div>
				)}
			</For>
		</div>
	)
}
