import {
	createContext,
	createEffect,
	For,
	mergeProps,
	on,
	onCleanup,
	Show,
	useContext,
	type ComponentProps,
} from "solid-js"
import { createMutable } from "solid-js/store"

import { drop, recompose, debounce, type ComponentLike, type ComposableComponentProps } from "#/lib/mod"
import { BasicInput, fuzzySort, Spinner, Tag } from "#/components/mod"

type Ctx<TItem, TMapped extends string> = ReturnType<typeof createDropdown<TItem, TMapped>>

type DropdownConfiguration<TItem, TMapped extends string> =
	& {
		options?: TItem[]
		selected?: TItem | TItem[]
		error?: string

		display?(item: TItem): TMapped | null
		apply?(item: TItem, ctx: Ctx<TItem, TMapped>): void
		search?(value: string): TItem[] | Promise<TItem[]>
		compare?(a: TItem, b: TItem): boolean

		afterSelect?(item: TItem, text: string, ctx: Ctx<TItem, TMapped>, reason?: "cancel"): void | false
		afterInput?(e: InputEvent): void
	}
	// & ComponentProps<"div">
	& Partial<Pick<ComponentProps<typeof BasicInput>, "disabled" | "placeholder">>

let DropdownContext = createContext<Ctx<any, any>>()
let useDropdownContext = <TItem, TMapped extends string = string>() =>
	useContext(DropdownContext) as Ctx<TItem, TMapped>

// TODO: refactor this
export function createDropdown<I, TMapped extends string = string>(root: DropdownConfiguration<I, TMapped>) {
	let fallback_props = {
		options: [],
		selected: [],
		search: (value: string) => fuzzySort(value, root.options).map(x => x.item),
		compare: (a, b) => a == b,
		display: (item: I) => item?.toString() as TMapped,
		apply(item: I) {
			let mapped = root.display(item)
			ctx.input.value = mapped ?? null // ?? null to avoid `undefined` string in input
		},
	} satisfies typeof root

	root = mergeProps(fallback_props, root)

	let ctx = {
		input: null as HTMLInputElement,
		root,
		get selected_as_array() {
			return Array.isArray(root.selected) ? root.selected : [root.selected]
		},
		afterSelect,
		requestOpen,
		search,

		state: createMutable({
			opened: false,
			searching: false,
			hover: root.options[0],
			all_suggestions: [...root.options],
		}),

		get filtered_suggestions() {
			return ctx.state.all_suggestions.filter(item => !ctx.selected_as_array.some(s => ctx.root.compare(s, item)))
		}
	}

	function afterSelect(opt: I) {
		// inputRef.value = opt.text
		root.apply(opt, ctx)

		ctx.state.hover = opt
		ctx.state.opened = false

		root.afterSelect?.(opt, ctx.input.value, ctx)
	}
	async function requestOpen() {
		if (ctx.state.opened) return

		document.activeElement !== ctx.input && ctx.input.focus()

		// if (!ctx.state.suggestions.length)
		await search(ctx.input.value)

		ctx.state.opened = true
	}

	async function search(str: string) {
		ctx.state.searching = true

		let result = await async function() {
			if (!root.search) {
				return [...root.options]
			}
			let result = await root.search(str)
			if (!result.length) {
				return [...root.options]
			}
			return result
		}()

		ctx.state.all_suggestions = result
		ctx.state.searching = false
	}

	return ctx
}

type DropdownProps<TItem> = {
	Input?: ComponentLike<typeof BasicInput>
	Suggestions?: ComponentLike<typeof Dropdown.Suggestions<TItem>>
	RightIcon?: ComponentLike<typeof Dropdown.RightIcon>
	Error?: ComponentLike<typeof BasicInput.Error>

	class?: string
} & Partial<Parameters<typeof createDropdown<TItem>>[0]> & ComposableComponentProps<"div">

export function Dropdown<TItem>(props: DropdownProps<TItem>, ctx?: Ctx<TItem, any>) {
	let { composed, uncomposed } = recompose(
		props,
		"Input",
		"Suggestions",
		"RightIcon",
		"Error",
		"error",
		"search",
		"compare",
		"display",
		"apply",
		"afterSelect",
		"afterInput",
		"options",
		"disabled",
		"selected",
		//
		"class",
		"classList",
	)
	let {
		Input = BasicInput,
		Suggestions = Dropdown.Suggestions,
		RightIcon = Dropdown.RightIcon,
		Error = BasicInput.Error,
	} = uncomposed

	ctx ??= createDropdown<TItem>(uncomposed)

	// TODO: replace effects with direct calls?
	createEffect(() => {
		if (!ctx.root.options?.length) {
			return
		}

		ctx.state.all_suggestions = [...ctx.root.options]
		ctx.state.hover = ctx.root.options[0]
	})
	createEffect(() => !ctx.state.opened && (ctx.state.hover = null))
	createEffect(() => ctx.selected_as_array.forEach(item => ctx.root.apply(item, ctx)))

	function findSelectedTarget() {
		let target = ctx.input.value.trim()
		return ctx.state.all_suggestions.find(sug => ctx.root.display(sug) === target)
	}

	let debouncedSearch = debounce(ctx.search, ctx.root.options.length < 200 ? 300 : 550, false, true)

	function onCancel(e) {
		if (!e.currentTarget.contains(e.relatedTarget)) {
			// TODO make better logic here

			if (ctx.state.opened) {
				ctx.state.opened = false
			}
			let wish = findSelectedTarget()
			ctx.root.afterSelect?.(wish, ctx.input.value, ctx, "cancel")
		}
	}

	function onKeyDown(ev: KeyboardEvent) {
		// if (!s.opened) return

		// in mobile browser key instead of code
		let key = ev.code.length ? ev.code : ev.key

		if (["Tab", "ArrowDown", "ArrowUp", "Enter"].includes(key)) {
			ev.preventDefault()
			ev.stopPropagation()
		}

		let index = ctx.filtered_suggestions.indexOf(ctx.state.hover)

		switch (key) {
			case "Enter": {
				if (!ctx.state.hover && !ctx.input.value.length) {
					return
				}

				let opt = ctx.filtered_suggestions[index] ?? findSelectedTarget()
				// inputRef.value = curOpt.text
				// requestSearch(s.hover)
				let result = ctx.root.afterSelect?.(opt, ctx.input.value, ctx)
				if (result !== false) {
					ctx.state.opened = false
					ctx.root.apply(opt, ctx)
				}
				break
			}
			case "Tab":
				if (!ctx.state.opened) ctx.requestOpen()
			case "ArrowUp":
			case "ArrowDown": {
				if (index < 0) {
					ctx.state.hover = ctx.filtered_suggestions[0]
					return
				}

				if (key === "ArrowDown" || key === "Tab" && !ev.shiftKey) {
					index++
				}
				if (key === "ArrowUp" || key === "Tab" && ev.shiftKey) {
					index--
				}

				if (index < 0) {
					index = ctx.filtered_suggestions.length - 1
				}
				if (index > ctx.filtered_suggestions.length - 1) {
					index = 0
				}

				let opt = ctx.filtered_suggestions[index]

				ctx.state.hover = opt
				ctx.root.apply(opt, ctx)
				// inputRef.value = opt.text

				break;
			}
			case "Escape":
				ctx.state.opened = false
				break
		}
	}

	function onInput(ev: InputEvent & { currentTarget: HTMLInputElement; target: Element }) {
		ctx.state.opened = true
		debouncedSearch(ev.currentTarget.value)
		// let first = s.suggestions[0]
		// if (first) {
		//    s.hover = first
		// }
		ctx.root.afterInput?.(ev)
	}

	return Object.assign(
		<DropdownContext.Provider value={ctx}>
			<dropdown
				tabIndex={0} // https://stackoverflow.com/a/42764495/8086153
				onFocusOut={onCancel}
				onKeyDown={onKeyDown}
				{...composed}
				classList={{
					...uncomposed.classList,
					[uncomposed.class]: uncomposed.class != null,
					":c: flex flex-col relative contain-none rounded-8px outline-(solid 1px offset-0) m-inline-1px": true,
					":c: dark:(bg-gray-800 c-white outline-gray-700/70) light:(bg-gray-000 c-black-999 outline-gray-200) focus-within:outline-blue-500": true,
					":c: outline-red-500!": ctx.root.error != null,
				}}
			>
				<Input
					ref={ref => ctx.input = ref}
					onClick={ctx.requestOpen}
					disabled={ctx.root.disabled}
					onInput={onInput}
					tabIndex={-1}
					classList={{
						"uno-layer-v2:(font-500 pr8 outline-none)": true
					}}
				/>
				<RightIcon />
				<Show when={ctx.state.opened}>
					<Suggestions />
				</Show>
			</dropdown>
			<Show when={ctx.root.error}>
				<Error error={ctx.root.error} />
			</Show>
		</DropdownContext.Provider>,
		ctx,
	)
}

Dropdown.RightIcon = function(props: ComponentProps<typeof BasicInput.IconW>) {
	let ctx = useDropdownContext()

	function onClick() {
		if (ctx.root.disabled) {
			return
		}
		if (ctx.state.searching) {
			return
		}
		if (ctx.state.opened) {
			ctx.state.opened = false
			return
		}
		ctx.requestOpen()
	}

	return (
		<BasicInput.IconW
			{...props}
			right
			iconc={ctx.state.searching ? undefined : !ctx.state.opened ? "i-hero:chevron-down" : "i-hero:chevron-up"}
			Icon={p => (
				<Show
					when={ctx.state.searching}
					children={<Spinner class=":c: w-6 h-6 dark:text-gray-400 light:text-gray-600" {...p} />}
					fallback={<i class=":c: w-6 h-6 dark:text-gray-400" {...p} />}
				/>
			)}
			onClick={onClick}
		// disabled={ctx.root.disabled}
		/>
	)
}

type DropdownSuggestionProps<TItem> = {
	item: TItem
	index: number
} & ComposableComponentProps<"div">

Dropdown.Suggestion = function <TItem>(props: DropdownSuggestionProps<TItem>) {
	let other = drop(props, "item", "index", "classList")

	let ctx = useDropdownContext<TItem>()

	return (
		<div
			onPointerDown={e => e.preventDefault()}
			onClick={[ctx.afterSelect, props.item]}
			tabIndex={props.index + 2}
			children={ctx.root.display(props.item)}
			{...other}
			classList={{
				...props.classList,
				[":c: h10 w-full overflow-hidden text-sm text-ellipsis ws-nowrap rounded-2 p-inline-3 font-500"]: true,
				[":c: leading-[2.8] dark:hover:bg-gray-700/30 light:hover:bg-gray-100/30"]: true,
				[":c: uno-layer-v2:(dark:bg-gray-700 light:bg-gray-100)"]: ctx.state.hover === props.item,
			}}
		/>
	)
}

type DropdownSuggestionsProps<TItem> = {
	Suggestion?: ComponentLike<typeof Dropdown.Suggestion<TItem>>
	class?: string
} & ComposableComponentProps<"div">

Dropdown.Suggestions = function <TItem>(props: DropdownSuggestionsProps<TItem>) {
	let { Suggestion = Dropdown.Suggestion } = props

	let other = drop(props, "Suggestion", "class", "classList")

	let ctx = useDropdownContext<TItem>()

	let ref: HTMLDivElement
	let children = new Map<TItem, HTMLDivElement>()

	createEffect(on(() => ctx.state.hover, (v) => {
		let el = children.get(v)
		if (!el) return

		let { height: container_height } = ref.getBoundingClientRect()
		if (el.offsetTop + el.clientHeight > ref.scrollTop + container_height) {
			let { height: el_height } = el.getBoundingClientRect()
			ref.scrollTo({ top: el.offsetTop - container_height + el_height * 3, behavior: "smooth" })
		}
		else if (el.offsetTop < ref.scrollTop) {
			let { height: el_height } = el.getBoundingClientRect()
			ref.scrollTo({ top: el.offsetTop - el_height * 3, behavior: "smooth" })
		}
	}))

	return (
		<Show when={ctx.filtered_suggestions.length > 0}>
			<suggestions
				{...other}
				ref={r => ref = r}
				classList={{
					...props.classList,
					[props.class]: !!props.class,
					":c: [&>option+option]:mt4px": true,
					":c: overflow-(x-hidden y-auto) scrollbar-width-thin overscroll-contain": true,
					":c: absolute top-[calc(100%+8px)] max-h67 w-full rounded-2 text-4 p1 z1000": true,
					":c: light:bg-gray-000 dark:bg-gray-800 outline-(1px solid) dark:outline-gray-700 light:outline-gray-100": true,
				}}
			>
				<For each={ctx.filtered_suggestions}
					children={(item, i) => <Suggestion ref={r => {
						children.set(item, r)
						onCleanup(() => children.delete(item))
					}} item={item} index={i()} />}
				/>
			</suggestions>
		</Show>
	)
}

type DropdownMultiSelectInputProps<TItem> = {
	onRemove?(item: TItem, ctx: Ctx<TItem, any>): any
	Input?: ComponentLike<"input">
	disabled?: boolean
	ref?(ref: HTMLInputElement): void
} & ComposableComponentProps<"input">

Dropdown.MultiSelectInput = function <TItem>(_props: DropdownMultiSelectInputProps<TItem>) {
	let ctx = useDropdownContext<TItem>()

	let { composed, uncomposed } = recompose(_props, "onRemove", "Input", "disabled", "classList")
	let { Input = BasicInput } = uncomposed

	return (
		<field classList={{
			":c: uno-layer-v2:[&>input]:h-auto": true,
			":c: flex flex-wrap items-stretch gap-1 w-full h-auto overflow-hidden text-ellipsis p-1 min-h-10 relative border-none rounded-inherit": true,
			":c: light:bg-gray-000 dark:bg-gray-800": !uncomposed.disabled,
			":c: dark:bg-gray-800/50 light:bg-gray-000/50": uncomposed.disabled,
		}} onClick={() => document.activeElement !== ctx.input && ctx.input.focus()}
		>
			<For
				each={ctx.selected_as_array}
				children={(opt, i) => (
					<Tag
						class=":c: uno-layer-v1:(flex items-center gap-1 p-block-2 p-inline-3 text-3 c-blue-500 font-500 dark:bg-blue-900 light:bg-blue-200 rounded-18px h8)"
						Text={() => <span class="shrink-0" innerText={ctx.root.display(opt)} />}
						Icon={() => <i class="i-hero:x-mark-20-solid size-5 ptr" onClick={() => uncomposed.onRemove?.(opt, ctx)} />}
					/>
				)}
			/>
			<Input
				type="text"
				disabled={ctx.root.disabled}
				placeholder={ctx.root.placeholder}
				{...composed}
				classList={{
					...uncomposed.classList,
					":c: uno-layer-v2:pl0": ctx.selected_as_array.length > 0,
					":c: uno-layer-v2:(border-none min-w-30px text-sm flex-1)": true
				}}
			/>
		</field>
	)
}
