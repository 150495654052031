import { createEffect, onCleanup } from "solid-js"
import { modifyMutable, reconcile, unwrap } from "solid-js/store"
import toast, { type Toast } from "solid-toast"

import IconUrl from "#/assets/branding/logo-icon.svg?url"

import { useAuth, useCache } from "#/lib/mod"
import { EntityType, EntityUpdateType, OnlineStatus } from "#/proto/schema"

import type { useRpc } from "./rpc.context"

let entityTypeToCacheKey = (entity_type: EntityType) => {
	switch (entity_type) {
		case EntityType.USER:
			return "users"
		case EntityType.CHAT:
			return "chats"
		case EntityType.MESSAGE:
			return "messages"
		case EntityType.DEAL:
			return "deals"
		case EntityType.POST:
			return "posts"
		case EntityType.PROJECT:
			return "projects"
		case EntityType.PROJECT_COMMENT:
			return "project_comments"
		case EntityType.TAG:
			return "tags"
		default:
			throw new Error(`Unknown entity type ${entity_type}`)
	}
}
export function registerRpcHandler(ctx: ReturnType<typeof useRpc>) {
	let cache = useCache(),
		auth = useAuth()

	ctx.emitter.on("ping", (_, msg) => ctx.sendAway({ $case: "ping", ping: null }, { answer_to: msg.id }))

	ctx.emitter.on("ac_batch_update", (update) => {
		cache.updateBatch(update as any)
	})

	ctx.emitter.on("ac_entity_updated", ({ entity_type, num, entity, update_type }) => {
		let collection = cache.entities[entityTypeToCacheKey(entity_type)]
		let iof = collection.findIndex(x => x.num === Number(num))
		if (iof > -1) {
			if (update_type === EntityUpdateType.COMPLETE) {
				// Replace state.user with the specified object (deleting any other fields)
				modifyMutable(collection[iof], reconcile(entity as any))
				// collection[iof] = entity
			}
			else if (update_type === EntityUpdateType.PARTIAL) {
				Object.assign(collection[iof], entity)
			}
			console.debug("entity", entity_type, "updated", unwrap(collection[iof]))
		}
		else {
			collection.push(entity as any)
			console.debug("entity", entity_type, "inserted", unwrap(entity))
		}
	})

	ctx.emitter.on("ac_entity_deleted", ({ entity_type, num }) => {
		let collection = cache.entities[entityTypeToCacheKey(entity_type)]
		let iof = collection.findIndex(x => x.num === Number(num))
		let result = collection.spliceSafe(iof, 1)
		console.debug("entity", entity_type, "deleted", unwrap(result))
	})

	ctx.emitter.on("ac_refresh_presence", ({ map }) => {
		cache.entities.presense_list.spliceSafe(0, cache.entities.presense_list.length)

		let arr = Object.entries(map).map(([num, status]) => ({
			num: Number(num),
			status: status as OnlineStatus,
		}))
		cache.update("presense_list", arr)
	})

	ctx.emitter.on("ac_presence_changed", ({ user_num, status, last_seen_at }) => {
		let { presense_list, users } = cache.entities
		let num = Number(user_num)

		switch (status) {
			case OnlineStatus.ONLINE:
			case OnlineStatus.AWAY: {
				cache.update("presense_list", [{ num, status }])
				break
			}
			case OnlineStatus.OFFLINE: {
				presense_list.spliceSafe(presense_list.findIndex(x => x.num === num), 1)
				let user = users.find(u => u.num === num)
				if (user && last_seen_at) {
					user.last_seen_at = Number(last_seen_at)
				}
			}
		}
	})

	function onVisibilityChange() {
		if (auth.is_authenticated && ctx.socket.status._ === "Connected") {
			ctx.sendAway({
				$case: "cmd_presence_changed",
				cmd_presence_changed: !document.hidden ? OnlineStatus.ONLINE : OnlineStatus.AWAY,
			})
		}
	}
	document.addEventListener("visibilitychange", onVisibilityChange)
	onCleanup(() => document.removeEventListener("visibilitychange", onVisibilityChange))

	let update_toast_id: string
	ctx.emitter.on("ac_version_info", (server_version) => {
		console.log("server version:", server_version, "| client version:", SFERADEL_VERSION)

		if (server_version == SFERADEL_VERSION) {
			return
		}

		if (!SFERADEL_VERSION || !server_version) {
			console.warn("incorrect version!")
			return
		}

		let message = (t: Toast) => (
			<div class="flex flex-col gap2 dark:bg-gray-700/90 light:bg-gray-100/90 backdrop-blur-2px p8px rounded-2 max-w-[var(--viewport-width)]">
				<div>
					Доступна новая версия: <span class="c-green fw-bold" innerText={server_version} />
					<br />
					Текущая: <span class="c-red fw-bold" innerText={SFERADEL_VERSION} />
				</div>
				<div class="flex gap2">
					<div
						class="bg-gray-600 hover:bg-gray-400 p2 rounded-2 ptr"
						innerText={"Перезагрузить"}
						onClick={location.reload.bind(location)}
					/>
					<div
						class="hover:bg-gray-400 p2 rounded-2 ptr"
						innerText={"Позже"}
						onClick={[toast.remove, t.id]}
					/>
				</div>
			</div>
		)

		if (update_toast_id) {
			// dprint-ignore
			try { toast.dismiss(update_toast_id) }
			catch (e) { }
		}

		update_toast_id = toast.custom(message, { duration: Number.POSITIVE_INFINITY })
	})

	ctx.emitter.on("ac_message_received", async (res) => {
		let { sender, chat_memberships, chat, message } = res

		cache.update("users", [sender])
		if (chat_memberships) {
			cache.update("chat_memberships", [chat_memberships])
		}
		cache.updateBatch({ chats: [chat], messages: [message] })

		if (sender.num === auth.user.num) {
			navigator.vibrate?.(100)
		}
		else {
			navigator.vibrate?.([200, 100, 200])

			let title = `Новое сообщение от ${sender.name}`
			let notification = new Notification(title, {
				requireInteraction: false,
				body: message.text,
				badge: "Faundr",
				icon: IconUrl,
			})
			Android?.notify(title)
		}
	})

	let ACITVITY_TIMEOUT_MS = import.meta.env.DEV ? 10_000 : 90_000
	createEffect(() => {
		if (ctx.socket.status._ !== "Connected") return

		let activity_events = [
			"mousedown",
			"mousemove",
			"touchstart",
			"scroll",
			"keydown",
		] as const

		for (let ev of activity_events) {
			document.addEventListener(ev, onAnyActivity)
		}
		onCleanup(() => {
			for (let ev of activity_events) {
				document.removeEventListener(ev, onAnyActivity)
			}
		})

		function onAnyActivity() {
			clearTimeout(activity_timeout)

			let { status } = cache.resolve("presense_list", auth.user?.num) ?? {}
			if (!status) {
				return
			}

			if (status !== OnlineStatus.ONLINE) {
				ctx.sendAway({ $case: "cmd_presence_changed", cmd_presence_changed: OnlineStatus.ONLINE })
			}

			activity_timeout = window.setTimeout(onActivityTimeout, ACITVITY_TIMEOUT_MS)
		}

		let activity_timeout = window.setTimeout(onActivityTimeout, ACITVITY_TIMEOUT_MS)
		function onActivityTimeout() {
			ctx.sendAway({ $case: "cmd_presence_changed", cmd_presence_changed: OnlineStatus.AWAY })
		}
	})
}
