import { langs } from "#/lib/appearance/i18n"

export let interest_langs = langs({
	ru: {
		your_interests: "Ваши интересы",
		choose_interests: "Выберите до пяти интересов",
		finish: "Закончить",
	},
	en: {
		your_interests: "Your Interests",
		choose_interests: "Choose one or more",
		finish: "Finish",
	},
	ua: {
		your_interests: "Ваші інтереси",
		choose_interests: "Виберіть один декілька",
		finish: "Finish",
	},
})

export let activities_langs = langs({
	ru: {
		title: "Чем вы занимаетесь?",
		choose_variants: "Выберите один или несколько вариантов",
		activities: {
			Investor: "Инвестор",
			Entrepreneur: "Предприниматель",
			Freelancer: "Фрилансер",
		},
	},
	en: {
		title: "Who are you?",
		choose_variants: "Choose one or more items",
		activities: {
			Investor: "Investor",
			Entrepreneur: "Entrepreneur",
			Freelancer: "Ereelancer",
		},
	},
	ua: {
		title: "Who are you?",
		choose_variants: "Choose one or more items",
		activities: {
			Investor: "Investor",
			Entrepreneur: "Entrepreneur",
			Freelancer: "Ereelancer",
		},
	},
})

export let avatar_langs = langs({
	ru: {
		upload_picture: "Загрузите фото",
		skip: "Пропустить",
	},
	en: {
		upload_picture: "Upload photo",
		skip: "Skip",
	},
	ua: { upload_picture: "Upload photo", skip: "Skip" },
})

export let introduction_langs = langs({
	ru: {
		introduce_yourself: "Представьтесь",
		name: {
			label: "Имя",
			placeholder: "Владислав",
			clientError: "только латинские и кирриллические буквы",
		},
		surname: {
			label: "Фамилия",
			placeholder: "Савельев",
			clientError: "только латинские и кирриллические буквы",
		},
		nickname: {
			label: "Никнейм",
			placeholder: "vladbmw530",
			clientError: "только латинские буквы, цифры и символ _ от 2 до 20 символов",
		},
	},
	en: {
		introduce_yourself: "What's your name?",
		name: {
			label: "Name",
			placeholder: "Tom",
			clientError: "only latin and cyrillic letters",
		},
		surname: {
			label: "Surname",
			placeholder: "Lindemann",
			clientError: "only contain latin and cyrillic letters",
		},
		nickname: {
			label: "Nickname",
			placeholder: "till_lindemann_official",
			clientError: "only contain latin letters and digits",
		},
	},
	ua: {
		introduce_yourself: "What's your name?",
		name: {
			label: "Name",
			placeholder: "Tom",
			clientError: "only contain latin and cyrillic letters",
		},
		surname: {
			label: "Surname",
			placeholder: "Lindemann",
			clientError: "only contain latin and cyrillic letters",
		},
		nickname: {
			label: "Nickname",
			placeholder: "till_lindemann_official",
			clientError: "only contain latin letters and digits",
		},
	},
})
