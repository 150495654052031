import { type ComponentProps } from "solid-js"
import { recompose } from "#/lib/rx/mod"

export function ItemIconWrapper(props: { circle_cl?: string } & ComponentProps<"div">) {
	let { composed, uncomposed } = recompose(props, "ref", "class", "classList", "children", "circle_cl")

	return (
		<div
			{...composed}
			classList={{
				":c: flex-center min-w-10 w-fit relative overflow-visible ptr": true,
				":c: [&>div:nth-child(1)]:(active:bg-gray-700/40 hover:bg-gray-700/40)": true,
				[uncomposed.class]: !!uncomposed.class,
				...uncomposed.classList,
			}}
			children={[
				<div class="abs-centered inset-0 size-10 rounded" classList={{ [props.circle_cl]: !!props.circle_cl }} />,
				props.children,
			]}
			use:noripple
		/>
	)
}
