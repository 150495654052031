type FetchParams = Omit<RequestInit, "body"> & {
	qs?: Record<string, string | number | boolean>
	onUploadProgress?(progress: number): void
	modify?(params: FetchParams): void
	body?: any
}

export type ApiError = {
	_: "err_normal"
	err: { message: string }
} | {
	_: "err_network"
	err: Error
} | {
	_: "err_bad_response"
	response: Response
} | {
	_: "err_unknown"
	response: Response
}
export type ApiFetchResult<T> = T | ApiError

function createApi() {

	let headers = {}

	async function fetcher<TResult>(url: string | URL, opts: FetchParams = {}): Promise<ApiFetchResult<TResult>> {
		if (opts.body instanceof Blob && opts.onUploadProgress) {
			let bytes_uploaded = 0
			let bytes_count = opts.body.size
			let stream = new TransformStream({
				transform(chunk, controller) {
					controller.enqueue(chunk)
					bytes_uploaded += chunk.byteLength
					opts.onUploadProgress(bytes_uploaded / bytes_count)
				},
				flush(controller) {
					opts.onUploadProgress(1)
				},
			})
			opts.body = opts.body.stream().pipeThrough(stream)
		}
		else if (opts.onUploadProgress) {
			throw new Error("body should be blob for progress tracking!")
		}

		if (opts.headers) Object.assign(opts.headers, headers)
		else opts.headers = structuredClone(headers)

		opts.modify?.(opts)

		let response = await fetch(url, opts).catch(Function.NOOP_ERR)

		if (response instanceof Error) {
			return { _: "err_network", err: response }
		}

		if (response.headers.get("content-length") === "0") {
			return response.ok ? null : { _: "err_unknown", response }
		}

		let obj = await response.json().catch(Function.NOOP_ERR) as TResult | { message: string } | Error

		if (obj instanceof Error) {
			return { _: "err_bad_response", response }
		}

		if (!response.ok) {
			if (obj && typeof obj === "object" && "message" in obj)
				return { _: "err_normal", err: obj }

			return { _: "err_unknown", response }
		}

		return obj as TResult
	}

	function requestJson<TResult = unknown>(url_str: string, opts: FetchParams = {}) {
		if (opts.body?.constructor === Object) {
			opts.body = JSON.stringify(opts.body)
			opts.method ??= "POST"
			opts.headers ??= {}
			opts.headers["content-type"] = "application/json"
		}

		let url = new URL(url_str, location.origin)
		if (opts.qs) {
			// @ts-ignore
			let params = new URLSearchParams(Object.entries(opts.qs).filter(([k, v]) => v !== undefined))
			url.search = params.toString()
		}

		return fetcher<TResult>(url, opts)
	}

	return {
		req: requestJson,
		headers,
	}
}
export let api = createApi()
