import { batch } from "solid-js"
import { type ReconcileOptions, modifyMutable, reconcile } from "solid-js/store"

export function mapData<
	S extends Record<string, any>,
	T extends Record<string, any>,
	KS extends keyof S = S extends { [key in infer KS]: any } ? KS : never,
	KT extends keyof T = T extends { [key in infer KT]: any } ? KT : never,
	KK extends (keyof S & keyof T) = KS & KT
>(
	state: S,
	target: T,
	keys: (KK | { [K in KS]: [K, Partial<S[K]>] }[KS])[],
	opts = {
		fallback_to_state: true,
	},
) {
	batch(() => {
		for (let entry of keys) {
			let [key, fallback] = Array.isArray(entry) ? entry : [entry, target[entry]] as const
			if (!opts.fallback_to_state) {
				// @ts-ignore
				state[key] = fallback
			}
			else {
				// @ts-ignore
				state[key] = fallback ?? state[key]
			}
		}
	})
}export function mergeMutableAdditive<T, U extends T>(
	target: T[],
	value: U[],
	options: ReconcileOptions = { merge: true }
) {
	let { key } = options
	batch(() => {
		for (let i = 0; i < value.length; i++) {
			let new_item = value[i]
			let existing_item_index = target.findIndex(item => item[key] != null && item[key] === new_item[key])
			if (existing_item_index < 0) {
				target.push(new_item)
			}
			else {
				modifyMutable(
					target[existing_item_index],
					// TODO make special recursive merger for each entitiy type
					reconcile({ ...target[existing_item_index], ...new_item }, options)
				)
			}
		}
	})
}

