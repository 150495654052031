import { For } from "solid-js"
import { type ComponentLike } from "#/lib/rx/mod"
import { MIME_EXTENSION } from "#/lib/media/mod"

export type PinnedAttachment = { url: string; blob: Blob }

type DialogHandleProps = {
	onFilesChoosen?(files: FileList): void
	Input?: ComponentLike<"input">
}
export function FileAttachmentsDialogHandle(props: DialogHandleProps) {
	let {
		Input = p => <input {...p} />
	} = props

	let accept = Object.values(MIME_EXTENSION)
		.filter(ext => ext.startsWith("image/"))
		.join(", ")

	function onFilesAdd(ev: Event & { currentTarget: HTMLInputElement }) {
		let { files } = ev.currentTarget
		if (!files.length) {
			return
		}

		props.onFilesChoosen?.(files)
	}

	return (
		<Input
			classList={{
				":c: i-hero:plus p0 m0 size-6 rounded border-none c-gray-300 ptr": true,
				":c: [&::-webkit-file-upload-button]:display-[none]! [&::file-selector-button]:display-[none]!": true
			}}
			type="file"
			name="attachment"
			multiple
			accept={accept}
			onchange={onFilesAdd}
		/>
	)
}

type FileAttachmentsGalleryProps = {
	onFileRemove?(file: PinnedAttachment, index: () => number): void
	files: PinnedAttachment[]

	Gallery?: ComponentLike<"div">
}

export function FileAttachmentsGallery(props: FileAttachmentsGalleryProps) {
	let { Gallery = p => <attachments {...p} /> } = props

	return (
		<Gallery
			class=":c: grid grid-flow-col auto-cols-[100px] gap-[5px] overflow-x-auto overflow-y-clip scrollbar-none overscroll-contain"
			style={{
				display: !props.files.length ? "none" : null,
			}}
		>
			<For
				each={props.files}
				children={(attachment, i) => (
					<div class=":c: w-100px h-100px relative bg-gray-700/90">
						<div
							class=":c: absolute top-0 right-0 p-0.5 bg-gray-800/80 flex-center rounded-8px"
							onclick={() => props.onFileRemove?.(attachment, i)}
							children={<i class="i-hero:trash-20-solid c-red-600" />}
						/>
						<img src={attachment.url} class="w-full h-full object-contain" />
					</div>
				)}
			/>
		</Gallery>
	)
}
