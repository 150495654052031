import { SUPPORTED_AUDIOS } from "#/lib/media/mod"

export type Recording = Exclude<Awaited<ReturnType<typeof createVoiceRecorder>>, Error>

export function createVoiceRecorder(opts: {
	onTick?: (spikes: number[], peak: number) => void
	onStart?: () => void
	onStop?: (err?: Error) => void
}) {
	let ctx = {
		mrec: null as MediaRecorder,
		started_at: 0 as number,
		waveform: [] as number[],
		data: null as Blob,

		start,
		stop,
		reset,
		loadData,
	}

	let data_promise: Promise<Blob>
	async function start() {
		reset()

		let stream = await navigator.mediaDevices?.getUserMedia?.({ audio: true }).catch(Function.NOOP_ERR)
		if (!stream || stream instanceof Error) {
			return new Error("Error while getting user media " + ((stream as Error)?.message ?? ""))
		}

		ctx.mrec = new MediaRecorder(stream, { mimeType: SUPPORTED_AUDIOS[0], audioBitsPerSecond: 32_000 })

		let { promise, reject, resolve } = Promise.withResolvers<Blob>()
		data_promise = promise

		ctx.mrec.ondataavailable = ev => resolve(ev.data)

		ctx.mrec.onerror = e => {
			let err = new Error("Error happened while recording voice message " + e.toString())
			opts.onStop?.(err)
			reject(err)
		}

		ctx.mrec.start()
		ctx.started_at = Date.now()

		opts.onStart?.()

		analyzeStream(ctx.mrec.stream)
	}

	function loadData() {
		return data_promise.then(data => ctx.data = data)
	}

	function stop() {
		if (!ctx.mrec) {
			return
		}

		ctx.mrec.stop()
		ctx.mrec.stream.getAudioTracks().forEach(track => track.stop())
	}

	function reset() {
		stop()
		ctx.data = null
		ctx.mrec = null
		ctx.waveform.splice(0, ctx.waveform.length)
		data_promise = null
	}

	function analyzeStream(stream: MediaStream) {
		let audio_context = new AudioContext()
		let stream_source = audio_context.createMediaStreamSource(stream)

		let analyser = audio_context.createAnalyser()
		analyser.fftSize = 64
		stream_source.connect(analyser)

		let buffer_length = analyser.frequencyBinCount
		let data = new Uint8Array(buffer_length)

		let { waveform } = ctx

		function run() {
			if (!stream?.active) {
				return
			}

			analyser.getByteTimeDomainData(data)
			let [spikes, peak] = interpolateArray(data, 45)

			let sum = data.reduce((acc, current) => acc + current, 0)
			let mean = sum / buffer_length
			waveform.push(mean)

			opts.onTick?.(spikes, peak)

			requestAnimationFrame(run)
		}
		run()
	}

	return ctx
}

export function interpolateArray(data: ArrayLike<number>, fit_count: number) {
	let peak = 0
	let d = new Array<number>(fit_count)
	let springFactor = data.length / fit_count
	let left_filler = data[0]
	let right_filler = data[data.length - 1]
	for (let i = 0; i < fit_count; i++) {
		let idx = Math.floor(i * springFactor)
		let val = ((data[idx - 1] ?? left_filler) + (data[idx] ?? left_filler) + (data[idx + 1] ?? right_filler)) / 3
		d[i] = val
		if (peak < val) {
			peak = val
		}
	}
	return [d, peak] as const
}
