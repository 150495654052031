export * from "./visualize-audio-stream"

import type { User } from "@sferadel/ts-lib/entities"

export function getUserAvatarImage(user: Pick<User, "avatar_key">) {
	return location.origin + "/s3/" + user?.avatar_key
}

export function getPictureUrl(id: string) {
	if (id?.startsWith("http")) {
		return id
	}
	if (id?.at(0) === "/") {
		id = id.slice(1)
	}
	return id ? location.origin + "/s3/" + id : null
}

export function getImageDimension(src: string): Promise<{ width: number; height: number }> {
	return new Promise(resolve => {
		let img = new Image()
		img.onload = () => {
			resolve({
				height: img.naturalHeight,
				width: img.naturalWidth,
			})
			img.remove()
		}
		img.src = src
	})
}

export let isLocalImage = (url: string) => url?.startsWith("blob:")
export let blobToTypedArray = (blob: Blob) => blob.arrayBuffer().then(x => new Uint8Array(x))

export function getSupportedMimeTypes(media: "audio" | "video", types, codecs) {
	let isSupported = MediaRecorder.isTypeSupported
	let supported = []
	types.forEach(type => {
		let mimeType = `${media}/${type}`
		codecs.forEach(codec =>
			[
				`${mimeType};codecs=${codec}`,
				`${mimeType};codecs=${codec.toUpperCase()}`,
				// /!\ false positive /!\
				// `${mimeType};codecs:${codec}`,
				// `${mimeType};codecs:${codec.toUpperCase()}`
			].forEach(variation => {
				if (isSupported(variation)) {
					supported.push(variation)
				}
			})
		)
		if (isSupported(mimeType)) {
			supported.push(mimeType)
		}
	})
	return supported
}
// Usage ------------------
let video_types = ["webm", "ogg", "mp4", "x-matroska"]
let audio_types = ["webm", "ogg", "mp3", "x-matroska"]
let codecs = [
	"should-not-be-supported",
	"vp9",
	"vp9.0",
	"vp8",
	"vp8.0",
	"avc1",
	"av1",
	"h265",
	"h.265",
	"h264",
	"h.264",
	"opus",
	"pcm",
	"aac",
	"mpeg",
	"mp4a",
]
// export let supportedVideos = getSupportedMimeTypes("video", videoTypes, codecs)

export let SUPPORTED_AUDIOS = getSupportedMimeTypes("audio", audio_types, codecs)
// console.debug("top audio codec:", SUPPORTED_AUDIOS[0])
// console.debug(SUPPORTED_AUDIOS.join(" | "))

export let MIME_EXTENSION = {
	"image/webp": "webp",
	"image/x-icon": "ico",
	"image/x-ms-bmp": "bmp",
	"image/gif": "gif",
	"image/png": "png",
	"image/jpeg": "jpeg",
	"image/tiff": "tiff",
	"image/svg+xml": "svg",

	"audio/ogg": "ogg",
	"audio/mp4": "mp4",
	"audio/webm": "webm",

	"audio/mpeg": "mp3",
} as const
export function formatVoiceRecordDuration(duration_ms: number) {
	let parts = []

	let milliseconds = duration_ms % 1000
	duration_ms -= milliseconds
	milliseconds = Math.floor(milliseconds / 10)

	duration_ms = Math.floor(duration_ms / 1000)
	let seconds = duration_ms % 60
	duration_ms -= seconds

	duration_ms = Math.floor(duration_ms / 60)
	let minutes = duration_ms % 60
	duration_ms -= minutes

	duration_ms = Math.floor(duration_ms / 60)
	let hours = duration_ms % 60

	if (hours > 0) {
		parts.push(String(hours).padStart(2, "0"))
	}
	parts.push(String(minutes).padStart(hours > 0 ? 2 : 1, "0"))
	parts.push(String(seconds).padStart(2, "0"))

	return `${parts.join(":")},${String(milliseconds).padStart(2, "0")}`
}
