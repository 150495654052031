// https://github.com/Luka967/websocket-close-codes

import { EventEmitter } from "eventemitter3"
import { on, createContext, createEffect, onCleanup, useContext, type ParentProps } from "solid-js"
import { createMutable } from "solid-js/store"
import toast from "solid-toast"

import { env, isDiscriminatedValue, useAuth } from "#/lib/mod"
import { RpcMessage, type CbError } from "#/proto/schema"

import { registerRpcHandler } from "./rpc-handler"

let { setTimeout, setInterval } = window

type RpcVariant = RpcMessage["variant"]

type RpcIncomingNames<T = RpcVariant["$case"]> = T extends `cb_${string}` ? T
	: T extends `ac_${string}` | "ping" ? T
	: never
type RpcCmdNames<T = RpcVariant["$case"]> = T extends `cmd_${string}` ? T : never

type WrapperOfCase<K extends RpcVariant["$case"], T extends RpcVariant = RpcVariant> = T extends {
	$case: infer _U extends K
} ? T
	: never

let RPC_MAP = {
	cmd_send_message: ["ac_message_received", "cb_added_to_chat"],
	cmd_post_new: ["ac_batch_update"],
	cmd_project_vote: ["cb_project_vote"],
	cmd_project_add_comment: ["ac_project_comment_received"],
	cmd_project_remove_comment: ["ac_entity_updated"],
} as const satisfies Partial<Record<RpcCmdNames, RpcIncomingNames[]>>

type RpcMapping<Req extends keyof typeof RPC_MAP> = typeof RPC_MAP[Req]

type RpcEvents =
	& { RPC_MESSAGE: [msg: RpcMessage] }
	& {
		[key in RpcIncomingNames]: [
			variant: WrapperOfCase<key> extends { [k in key]: infer U } ? U : never,
			msg: GenericRpcMessage<WrapperOfCase<key>>,
		]
	}

type GenericRpcMessage<Variant extends RpcVariant> = Omit<RpcMessage, "variant"> & { variant: Variant }

const
	MAX_U32_VALUE = 4294967295,
	DEFAULT_PROGRESS_REPORT_TIMEOUT_MS = 90_000,
	MAX_MESSAGE_SIZE = 200 << 20 // 200 Mb

export function RpcContextProvider(props: ParentProps) {
	let auth = useAuth()
	let emitter = new EventEmitter<RpcEvents>()
	let socket = createWebsocketController({ onMessage })

	// let ws_telemetry = null
	// if (environment.can_use_telemetry) {
	// 	import("#/lib/telemetry").then(({ openReplay }) => {
	// 		ws_telemetry = openReplay.trackWs("rpc")
	// 	})
	// }

	let ctx = {
		socket,
		emitter,
		request,
		sendAway,
		sendRpc,
	}

	registerRpcHandler(ctx)

	auth.emitter.on("logout", () => socket.disconnect())
	window.addEventListener("online", onOnline)
	window.addEventListener("offline", onOffline) // https://bugs.webkit.org/show_bug.cgi?id=247943#c21
	window.addEventListener("beforeunload", onBeforeUnload)
	document.addEventListener("visibilitychange", onVisibilityChange)

	setTimeout(() => auth.is_authenticated && socket.tryConnect(), 3) // safari PWA after wake up should reconnect here.

	createEffect(on(() => [auth.is_authenticated, auth.user?.visible], ([is_authenticated, visible]) => {
		if (is_authenticated && visible) {
			console.log("[rpc]", "user is authenticated and visible, trying to connect")
			socket.tryConnect()
		}
	}, { defer: true }))

	onCleanup(() => {
		socket.disconnect("cleanup")
		auth.emitter.off("logout", socket.disconnect)
		window.removeEventListener("online", onOnline)
		window.removeEventListener("offline", onOffline)
		window.removeEventListener("beforeunload", onBeforeUnload)
		document.removeEventListener("visibilitychange", onVisibilityChange)
	})

	function onMessage({ data }: MessageEvent) {
		if (data.constructor !== ArrayBuffer) {
			console.warn("[rpc] websocket message of unknown type", data)
			return
		}
		let msg: RpcMessage | Error
		try { msg = RpcMessage.decode(new Uint8Array(data)) }
		catch (e) { msg = e as Error }

		if (msg instanceof Error) {
			console.warn("failed to decode incoming message")
			return
		}

		console.debug("[rpc]", msg)
		// ws_telemetry?.("RpcMessage", JSON.stringify(msg, null, 2), "down")

		emitter.emit("RPC_MESSAGE", msg)
		emitter.emit(msg.variant.$case as any, msg.variant[msg.variant.$case], msg)
	}

	let upload_interval: number

	function sendRpc(msg: RpcMessage, opts: UserSendOptions): RpcResult<never> {
		if (is_unloading) {
			console.debug("is_unloading, will not send rpc message")
			return { _: "err_not_connected" }
		}

		if (opts.answer_to && msg.answer_to == null) {
			msg.answer_to = opts.answer_to
		}

		let binary_message = RpcMessage.encode(msg).finish()
		let msg_size = binary_message.byteLength

		if (msg_size >= MAX_MESSAGE_SIZE) {
			return { _: "err_payload_too_big", size: (msg_size / (2 ** 20)) }
		}

		if (opts?.onUploadProgress) {
			clearInterval(upload_interval)

			let started_at = Date.now(), was_captured = false

			upload_interval = setInterval(() => {
				let { bufferedAmount: buf_size } = socket.ws

				if (buf_size > 0) {
					was_captured = true
					opts.onUploadProgress(1 - buf_size / msg_size)
					return
				}
				else if (was_captured) {
					opts.onUploadProgress(1)
					clearInterval(upload_interval)
				}
				else if (Date.now() - started_at > opts.timeout_ms) {
					clearInterval(upload_interval)
				}
			}, 100)
		}

		let send = () => {
			socket.ws.send(binary_message)
			// ws_telemetry?.("RpcMessage", JSON.stringify(msg, null, 2), "up")
		}

		if (socket.status._ !== "Connected") {
			console.warn("[rpc]", "====!!!==== TRYING TO SEND MESSAGE WITHOUT WS CONNECTION!", msg, opts)
			socket.tryConnect().then(send)
			return { _: "err_not_connected" }
		}

		// ensure first iteration of upload_interval runs before `ws.send()`
		queueMicrotask(send)
	}

	type UserSendOptions = {
		onUploadProgress?(progress: number): void
		timeout_ms?: number
		answer_to?: number
	}

	function sendAway(msg: RpcVariant, opts: UserSendOptions = {}): RpcResult<never> {
		opts.timeout_ms ??= DEFAULT_PROGRESS_REPORT_TIMEOUT_MS

		let rpc: RpcMessage = {
			id: Math.getRandomInt(0, MAX_U32_VALUE),
			variant: msg,
		}

		return sendRpc(rpc, opts)
	}

	function request<M extends RpcVariant>(
		msg: M extends { $case: infer _U extends RpcCmdNames } ? M : never,
		opts: UserSendOptions = {},
	) {
		type ReqKey = M extends { $case: infer U } ? U : never
		// @ts-ignore
		type ResKey = RpcMapping<ReqKey>[keyof RpcMapping<ReqKey>]
		// @ts-ignore
		type ResWrapper = WrapperOfCase<ResKey>
		type CorrectResponse = GenericRpcMessage<ResWrapper>

		let { resolve, promise } = Promise.withResolvers<RpcResult<CorrectResponse>>()

		opts.timeout_ms ??= DEFAULT_PROGRESS_REPORT_TIMEOUT_MS

		let timeout: number
		let id = Math.getRandomInt(0, MAX_U32_VALUE)
		let rpc_message: RpcMessage = { id, variant: msg }

		async function hook(msg: RpcMessage) {
			if (msg.answer_to === id) {
				clearTimeout(timeout)
				emitter.off("RPC_MESSAGE", hook)

				if (msg.variant.$case === "cb_error") {
					resolve({ _: "err_normal", cb_error: msg.variant.cb_error })
					return
				}
				resolve(msg as CorrectResponse)
				return
			}
		}

		emitter.on("RPC_MESSAGE", hook)

		function onTimeout() {
			if (socket.ws.bufferedAmount > 0) {
				console.warn("[rpc]: bufferedAmount > 0, cancelling rpc request timeout")
				timeout = setTimeout(onTimeout, opts.timeout_ms)
				return
			}
			emitter.off("RPC_MESSAGE")
			clearInterval(upload_interval)
			resolve({ _: "err_timeout" })
		}
		timeout = setTimeout(onTimeout, opts.timeout_ms)

		let result = sendRpc(rpc_message, opts)
		if (isDiscriminatedValue(result)) {
			resolve(result)
			return
		}

		return promise
	}

	function onOnline() {
		if (!auth.is_authenticated) return

		if (socket.status._ === "Disconnected") {
			console.log("[rpc] trying to reconnect as we're now online")
			socket.tryConnect()
		}
	}

	function onVisibilityChange() {
		if (!auth.is_authenticated) return

		if (document.hidden || !navigator.onLine) return

		if (socket.status._ === "Disconnected") {
			console.log("[rpc] trying to conenct as tab got active")
			socket.tryConnect()
			return
		}

		if (socket.status._ === "Connected" && !socket.ws) {
			console.log("[rpc] probably ios pwa wakeup, reconnecting ws")
			socket.tryConnect(true)
			return
		}
	}

	function onOffline() {
		if (!auth.is_authenticated) return

		console.log("[rpc] ios browser went sleep")
		socket.disconnect("sleep")
	}

	let is_unloading = false
	function onBeforeUnload() {
		is_unloading = true
		socket.disconnect("cleanup")
	}

	return Object.assign(<LiveContext.Provider value={ctx} children={props.children} />, { ctx })
}

let LiveContext = createContext<ReturnType<typeof RpcContextProvider>["ctx"]>()
export let useRpc = () => useContext(LiveContext)

const CONNECTION_TIMEOUT_MS = 3000
const MAX_CONNECTIONS_ATTEMPTS_IN_ROW = 5

export type SocketConnectionStatus = {
	_: "Disconnected"
	reason?: "sleep" | "cleanup"
} | {
	_: "Connecting"
} | {
	_: "Connected"
}

// TODO: implement ping timeouts to handle conenction change (VPN etc)
function createWebsocketController({ onMessage }) {
	let auth = useAuth()

	let ctx = createMutable({
		ws: null as WebSocket,
		should_abort_connecting_attempt: false,
		status: { _: "Disconnected" } as SocketConnectionStatus,

		tryConnect,
		disconnect,
	})

	async function connect() {
		if (ctx.ws?.readyState === WebSocket.CONNECTING) {
			console.warn("[rpc] there is already connecting socket")
			return
		}

		let websocket_path = `${location.protocol === "https:" ? "wss" : "ws"}://${location.host}/api/ws`
		let ws = new WebSocket(websocket_path, ["faundr.ru", auth.jwt, env.version, env.rt.platform.toString()]) // https://github.com/whatwg/websockets/issues/16#issuecomment-1997491158
		ws.binaryType = "arraybuffer"

		let { promise, reject, resolve } = Promise.withResolvers<WebSocket>()

		ws.onopen = () => {
			clearTimeout(timeout)
			ws.onerror = null
			ws.onclose = null
			resolve(ws)
		}

		ws.onclose = e => {
			let error_str = "WebSocket disconnected quickly."
			if (e.reason?.length) {
				error_str += ` Reason:  ${e.reason}`
			}
			if (e.code) {
				error_str += ` Code: ${e.code}`
			}
			reject(new Error(error_str))
		}

		let timeout = setTimeout(() => {
			if (ws !== ctx.ws) return
			if (ws?.readyState == WebSocket.CONNECTING) return

			console.warn("[socket] timeout connecting to", websocket_path)
			ws?.close(1000)
			reject(new Error("timeout connecting to websocket"))
		}, CONNECTION_TIMEOUT_MS)

		return promise
	}

	// TODO: check if need to disconnect (logout etc)
	async function tryConnect(force = false) {
		if (ctx.status._ === "Connecting") {
			console.warn("[socket] skipping connect() as we're already connecting")
			return
		}

		if (!force && ctx.status._ === "Connected") {
			console.warn("[rpc] skipping connect() as we're already connected")
			return
		}

		ctx.should_abort_connecting_attempt = false
		ctx.status = { _: "Connecting" }

		for (let attempt = 1; true; attempt++) {
			// Treat setting "Disconnected" externally as signal to abort attempts
			if (ctx.should_abort_connecting_attempt) {
				console.warn("[socket]: aborting connection attempts")
				break
			}

			let result = await connect().catch(Function.NOOP_ERR)
			if (result instanceof WebSocket) {
				console.log("[socket]: connected")
				if (attempt > 1) {
					toast.success("Подключение восстановлено")
				}
				ctx.ws = result
				ctx.status = { _: "Connected" }
				ctx.ws.onclose = onSuddenClose
				ctx.ws.onmessage = onMessage
				return
			}

			let timeout_ms = 2 ** attempt * 400 + Math.random() * 1500
			console.warn("[socket]: attempt", attempt, "failed to connect", result)
			await Promise.delay(timeout_ms)
		}
	}

	function onSuddenClose(e: CloseEvent) {
		ctx.status = { _: "Disconnected" }

		if (!e.wasClean) {
			console.log("[socket] connection closed NOT cleanely", e)
			tryConnect()
		}
		else {
			console.log("[socket] weboscket connection closed cleanly")
		}
	}

	type EjectedReason<T extends SocketConnectionStatus = SocketConnectionStatus> = T extends
		{ _: "Disconnected"; reason?: infer U } ? U : never

	function disconnect(reason: EjectedReason | null = null) {
		console.log("[socket] disconnecting")
		ctx.should_abort_connecting_attempt = true
		if (ctx.ws) {
			ctx.ws.onclose = null
			ctx.ws.close(1000 /* NORMAL */)
		}
		ctx.status = { _: "Disconnected", reason }
	}

	return ctx
}

export type RpcResult<T> = T | RpcError
export type RpcError = {
	_: "err_not_connected"
} | {
	_: "err_timeout"
} | {
	_: "err_payload_too_big"
	size: number
} | {
	_: "err_normal"
	cb_error: CbError
}


