export function throttle<F extends (...args: any[]) => void>(
	fn: F,
	ms: number,
	should_run_first = true,
	multiplier = 0,
) {
	let interval: number | null = null, is_pending: boolean, args: Parameters<F>, attempt = 1

	let clear = () => {
		clearInterval(interval!)
		interval = null
	}

	let ret = (..._args: Parameters<F>) => {
		is_pending = true
		args = _args

		if (!interval) {
			if (should_run_first) {
				is_pending = false
				fn(...args)
			}

			interval = setInterval(() => {
				if (!is_pending) {
					clear()
					return
				}

				is_pending = false
				fn(...args)
			}, ms + attempt * ms * multiplier) as any
		}
	}

	ret.clear = clear

	return ret
}

export function debounce<Fn extends (...args: any[]) => void>(
	fn: Fn,
	ms: number,
	should_run_first = true,
	should_run_last = true,
) {
	let waiting_timeout: number = null

	return function (...args: Parameters<Fn>) {
		if (waiting_timeout) {
			clearTimeout(waiting_timeout)
			waiting_timeout = null
		}
		else if (should_run_first) {
			fn(...args)
		}

		waiting_timeout = setTimeout(() => {
			if (should_run_last) {
				fn(...args)
			}

			waiting_timeout = null
		}, ms)
	}
}
