import { type ComponentProps, type JSX } from "solid-js"

import { type ComponentLike } from "#/lib/rx/types"
import { recompose } from "#/lib/rx/mod"

type TagProps = ComponentProps<"div"> & {
	Text?: ComponentLike<"span"> | JSX.Element
	Icon?: ComponentLike<"i"> | JSX.Element

	smol?: boolean
}

export function Tag(props: TagProps) {
	let { composed, uncomposed } = recompose(props, "Text", "Icon", "classList", "class", "children")
	let { Icon, Text = p => <span class="shrink-0" {...p} /> } = uncomposed

	return (
		<item
			{...composed}
			classList={{
				[props.class]: !!props.class,
				...uncomposed.classList,
				":c: flex items-center gap-1 p-block-2 min-w-fit text-3 font-500 rounded-18px": true,
				":c: dark:(bg-blue-800 c-blue-501) light:(bg-blue-000 c-blue-500)": true,
				"h8 p-inline-3": !props.smol,
				"h6 p-inline-2": props.smol,
			}}>
			{Text instanceof Function ? <Text /> : Text}
			{Icon instanceof Function ? <Icon /> : Icon}
			{uncomposed.children}
		</item>
	)
}
