import type { shapes } from "#/lib/data/shapes"
import { createContext, onCleanup, type ParentProps, useContext } from "solid-js"
import { api } from "#/lib/remote/api"
import { useAuth } from "#/lib/auth"
import { useCache } from "#/lib/cache/cache.context"
import { apiErrorHandled } from "../../lib/mod"

export function MessagesContextProvider(props: ParentProps) {
	let auth = useAuth()
	let cache = useCache()

	type Opts = {
		chats_nums?: number[]
		chats_cursor?: string
		msg_cursor?: string
		msg_count?: number
	}
	async function pull(opts: Opts = {}) {
		let query = { ...opts } as Record<string, string>

		if (opts.chats_nums) {
			query.chats_nums = opts.chats_nums.join(",")
		}

		type ApiChatsRepsonse = {
			chats: shapes.MinChat[]
			users: shapes.MinUser[]
			messages: shapes.Message[]
			chat_memberships: shapes.MinChatMembership[]
		}

		let response = await api.req<ApiChatsRepsonse>("/api/chats", { qs: query })

		if (apiErrorHandled(response, "Ошибка при загрузке чатов")) {
			return response
		}

		for (let key of ["chats", "messages", "users", "chat_memberships"] as const) {
			cache.update(key, response[key])
		}
		return response
	}

	function getOpponent(chat: shapes.MinChat) {
		if (!chat) {
			return null
		}
		if (chat._kind === "Dialog") {
			return cache.entities.users.find(user => user.num !== auth.user.num && chat.member_nums.includes(user.num))
		}
		return null
	}

	function onVisibilityChange() {
		if (!auth.is_authenticated) return
		if (!document.hidden) pull()
	}

	document.addEventListener("visibilitychange", onVisibilityChange)
	onCleanup(() => {
		document.removeEventListener("visibilitychange", onVisibilityChange)
	})

	let ctx = {
		pull,
		getOpponent,
	} as const

	return Object.assign(<MessagesContext.Provider value={ctx} children={props.children} />, { ctx })
}

let MessagesContext = createContext<ReturnType<typeof MessagesContextProvider>["ctx"]>()
export let useChats = () => useContext(MessagesContext)
