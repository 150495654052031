// reexport lib modules

export * from "./auth"
export * from "./behaviour"
export * from "./cookies"
export * from "./storage-keys"
export * from "./schedulers"
export * from "./overlay"
export * from "./md"

// reexport common modules
export { ROUTES, useInPageView, useRouter, Navigate, onPageWasNavigated } from "./navigation/mod"
export { api, type ApiFetchResult, type ApiError } from "./remote/api"
export { useRpc, type RpcResult, type RpcError } from "./remote/rpc.context"
export {
	signal,

	drop, recompose,

	Ref, mapData, trackDeep,

	type ComponentLike,
	type ComposableComponentLike,
	type ComposableComponentProps,
} from "./rx/mod"
export { langs, lang } from "./appearance/i18n"

export { useCache, IdbStoreKeys } from "./cache/cache.context"
export { useLayout } from "../layout.context"
export function haversineDistanceKm(x: { lon: number; lat: number} , y: { lon: number; lat: number} ) {
	let rad = Math.PI / 180, R = 6371 // Radius of the Earth in kilometers
	let dLat = (y.lat - x.lat) * rad, dLon = (y.lon - x.lon) * rad

	let a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
		+ Math.cos(x.lat * rad) * Math.cos(y.lat * rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	let distance = R * c // Distance in kilometers
	return distance
}

export { type shapes } from "./data/shapes"


// Lib things too
import toast, { type ToastOptions } from "solid-toast"
import { env } from "./behaviour"
import { type ApiError, type ApiFetchResult } from "./remote/api"
import { type RpcError, type RpcResult } from "./remote/rpc.context"


export const
	MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24,
	DEFAULT_DATE_LOCALE = "ru-RU"

/**
```md
TODO: refactor to i18n module?
cardinal is number of items
ordinal is order of items
resolved categories/types could be debuggied via `plural_rule.resolvedOptions().pluralCategories`
ru-RU: one, few, many, other
```
*/
export let PLURALS = {
	CardinalRule: new Intl.PluralRules(DEFAULT_DATE_LOCALE, { type: "cardinal" }),
	OrdinalRule: new Intl.PluralRules(DEFAULT_DATE_LOCALE, { type: "ordinal" }),
}

export function doNextFrame<Fn extends FrameRequestCallback>(fn: Fn) {
	if (env.rt.is_firefox) {
		return requestAnimationFrame(() => requestAnimationFrame(fn))
	}

	return requestAnimationFrame(fn)
}

export let onlyDev = <T, F = T>(value: T, fallback?: F) =>
	import.meta.env.DEV ? value : fallback ?? (value?.constructor?.() as T)

function cx(template: TemplateStringsArray, ...params: (string | number)[]) {
	let str = "", i = 0, c: string | number | null
	for (; i < template.length;) {
		if ((c = template[i++]).length > 1) str += c
	}
	for (i = 0; i < params.length - 1;) if ((c = params[i++]) != null) str += `${c} `
	if ((c = params[i]) != null) str += c

	return str.trim()
}

export function cl(...strs: string[]) {
	let obj: Record<string, true> = Object.fromEntries(strs.map(s => ([s, true])))
	return obj
}


// dprint-ignore
export function truncateText(text: string, {
	max_chars = 200,
	max_words = 5,
	squeeze = true,
	ellipsis = false,
} = {}) {
	if (squeeze) text = text.replace(/\n+/g, "\n")

	let cap_iof = -1, i = 0
	for (let len = text.length, wc = 0, last_word_len = 0; i < len; i++) {
		if (text[i] !== " ") {
			last_word_len = last_word_len + 1
		}
		else {
			if (i === len - 1) break

			while (text[i + 1] === " ") {
				i++
				continue
			}

			if (last_word_len > 3) wc++

			last_word_len = 0

			if (wc >= max_words || i >= max_chars) {
				cap_iof = i
				break
			}
		}
	}

	if (ellipsis && cap_iof > -1) {
		text = text.slice(0, cap_iof) + "..."
		cap_iof += 3
	}

	return {
		text,
		truncated: cap_iof > -1,
	}
}

export function isDiscriminatedValue<T, D>(result: T | D): result is D {
	return result && typeof result === "object" && "_" in result
}

export const isApiError = <T>(result: ApiFetchResult<T>): result is ApiError => isDiscriminatedValue(result)
export const isRpcError = <T>(result: RpcResult<T>): result is RpcError => isDiscriminatedValue(result)

let error_options: ToastOptions = { duration: 2500 }
export function apiErrorHandled<T>(result: ApiFetchResult<T>, prefix?: string): result is ApiError {
	if (!isDiscriminatedValue<ApiFetchResult<T>, ApiError>(result))
		return false

	console.error(result)

	let parts = [], postfix: string
	if (prefix) parts.push(prefix)

	switch (result._) {
		case "err_network":
			postfix = `Проблемы с подключением`
			break
		case "err_bad_response":
			postfix = `Неопозанный ответ от сервера. Попробуйте позже`
			break
		case "err_unknown":
			postfix = `Неизвестная ошибка`
			break
		case "err_normal":
			postfix = `${result.err.message}`
			break
	}
	postfix = "[API] " + postfix

	parts.push(postfix)

	toast.error(parts.join("\n"), error_options)
	return true
}

export function rpcErrorHandled<T>(result: RpcResult<T>, prefix?: string): result is RpcError {
	if (!isDiscriminatedValue<RpcResult<T>, RpcError>(result))
		return false

	console.error(result)

	let parts = [], postfix: string
	if (prefix) parts.push(prefix)

	switch (result._) {
		case "err_not_connected":
			postfix = `Отсутствует подключение к серверу`
			break
		case "err_payload_too_big":
			postfix = `Слишком большое сообщение`
			break
		case "err_timeout":
			postfix = `Превышено время ожидания ответа от сервера, попробуйте позже`
			break
		case "err_normal":
			postfix = `${result.cb_error.description}`
			break
	}
	postfix = "[RPC] " + postfix
	parts.push(postfix)

	toast.error(parts.join("\n"), error_options)
	return true
}


function getv(target: object, path: string, fallback: object = null) {
	let dot = typeof path === "string" ? path.indexOf(".") : -1

	if (dot === -1) {
		if (path.length) {
			if (path in target) {
				return target[path]
			}

			if (Array.isArray(target) && target.some((o) => Object.keys(o).includes(path))) {
				return target.map((item) => item[path])
			}
		}

		return fallback
	}

	return getv(
		Array.isArray(target) && !/^\d+$/.test(path.substring(0, dot))
			? target.map((item) => item[path.substring(0, dot)])
			: target[path.substring(0, dot)],
		path.substring(dot + 1),
		fallback,
	)
}


export let stringToColor = (text: string, lightness = 55, saturation = 76) =>
	window.crypto.subtle?.digest("SHA-1", new TextEncoder().encode(text))
		.then(x => new Uint8Array(x))
		.then(x => x.join("").slice(16))
		.then(Number)
		.then(hash => `hsl(${hash % 360},${saturation}%,${lightness}%)`)
