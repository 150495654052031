export * from "./messages"

import type { WorkerMessage, WorkerMessageInner } from "./messages";

let worker: Worker

export function registerWorker(w: Worker) {
	worker = w
}

export function requestWorker<Answer extends WorkerMessage | { _: "timeout" }>(m: WorkerMessageInner) {
	let { resolve, promise } = Promise.withResolvers<Answer>()

	let msg_id = Math.getRandomInt(0, Number.MIN_SAFE_INTEGER - 1)
	let timeout: number
	let msg: WorkerMessage = {
		id: msg_id,
		...m
	}

	let hook = function(ev: MessageEvent<WorkerMessage>, msg = ev.data) {
		if (msg.answer_to === msg_id) {
			// clearTimeout(timeout)
			worker.removeEventListener("message", hook)
			resolve(msg as Answer)
		}
	}

	worker.addEventListener("message", hook)

	// timeout = window.setTimeout(() => {
	// 	worker.removeEventListener("message", hook)
	// 	resolve({ _: "timeout" } as any)
	// }, 1000)

	worker.postMessage(msg)

	return promise
}
