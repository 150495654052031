import { mergeProps, Show, type JSX } from "solid-js"
import { createMutable } from "solid-js/store"
import toast from "solid-toast"

import { type ComponentLike } from "#/lib/mod"
import { formatVoiceRecordDuration, renderWaveform2 } from "#/lib/media/mod"

import { FileAttachmentsDialogHandle, FileAttachmentsGallery } from "./attachments-input"
import { createVoiceRecorder } from "./voice-recorder"

export function createMediaInput() {
	let refs = {
		wave_ref: null as HTMLCanvasElement,
		textarea: null as HTMLTextAreaElement,
	}

	let recorder = createVoiceRecorder({
		onTick(...args) {
			if (!refs.wave_ref) return

			st.voice.now = Date.now()
			renderWaveform2(refs.wave_ref, ...args)
		},
	})

	let st = createMutable({
		input: "",
		files: [] as { url: string; blob: Blob }[],
		voice: {
			is_recording: false,
			now: null as number,
		},
	})

	async function onPictureAdded(list: FileList, files = Array.from(list)) {
		if (st.files.length + files.length > 5) {
			toast.error("Only 5 files are available at once", { style: { background: "orange" }, duration: 1300 })
			files.splice(5 - st.files.length, Number.POSITIVE_INFINITY)
		}

		for (let file of files) {
			let url = URL.createObjectURL(file)
			st.files.push({ url, blob: file })
		}
	}

	async function OnMicrophoneClicked() {
		let result = await recorder.start()
		if (result instanceof Error) {
			toast.error("failed to start recording " + result.toString())
			return
		}
		st.voice.is_recording = true
	}

	function onTextAreaInput() {
		let { textarea } = refs
		st.input = textarea.value
		textarea.style.height = "0px"
		let height = Number.parseInt(getComputedStyle(textarea).height)
		if (textarea.scrollHeight > height) {
			textarea.style.height = textarea.scrollHeight + "px"
			return
		}
		textarea.style.removeProperty("height")
	}

	function clear() {
		recorder.stop()
		st.voice.is_recording = false
		st.input = ""

		for (let i = st.files.length - 1; i >= 0; i--) {
			URL.revokeObjectURL(st.files[i].url)
		}
		st.files.spliceSafe(0, st.files.length)
	}

	type MediaInputProps = {
		children: (c: typeof ctx) => JSX.Element
	}
	function MediaInput(props: MediaInputProps) {
		return props.children(ctx)
	}

	let ctx = Object.assign(st, {
		recorder,
		MediaInput,
		refs,
		clear,
	})

	MediaInput.FileAttachmentsGallery = (
		p: { FileAttachmentsGallery?: ComponentLike<typeof FileAttachmentsGallery> },
		props = mergeProps({
			FileAttachmentsGallery,
		}, p),
	) => (
		<props.FileAttachmentsGallery
			files={st.files}
			onFileRemove={(attachment, i) => {
				st.files.splice(i(), 1)
				URL.revokeObjectURL(attachment.url)
			}}
		/>
	)

	MediaInput.FileAttachmentsDialogHandle = (
		p: { FileAttachmentsDialogHandle?: ComponentLike<typeof FileAttachmentsDialogHandle> },
		props = mergeProps({
			FileAttachmentsDialogHandle,
		}, p),
	) => <props.FileAttachmentsDialogHandle onFilesChoosen={onPictureAdded} />

	MediaInput.TextArea = (
		p: { textarea?: ComponentLike<"textarea"> },
		Textarea = p.textarea ?? (p => <textarea {...p} />)
	) => (
		<Textarea
			classList={{
				":c: pt-5px p-l-2 w-full min-h-9 h-9 max-h-35 dark:bg-gray-800 light:bg-gray-000": true,
				":c: b-none outline-none rounded-4px resize-none text-sm leading-6": true,
				":c: scrollbar-width-thin webkit-scrollbar-w-36px": true,
				":c: [&::-webkit-resizer]:(hidden bg-transparent)": true,
				":c: disabled:(bg-gray-700 cursor-not-allowed)": true
			}}
			ref={r => refs.textarea = r}
			value={st.input}
			onInput={onTextAreaInput}
		/>
	)

	MediaInput.RecordingIcon = () => (
		<Show
			when={st.voice.is_recording}
			fallback={!st.input.length && !st.files.length && (
				<i
					class=":c: i-hero:microphone-solid c-gray-300 self-center ptr"
					onClick={OnMicrophoneClicked}
				/>
			)}
		>
			<i
				class=":c: i-hero:trash c-red-500 self-center"
				onclick={() => {
					let really = confirm("Stop and remove voice message?")
					if (!really) {
						return
					}
					recorder.stop()
					st.voice.is_recording = false
				}}
			/>
		</Show>
	)

	MediaInput.Waveform = (
		p: { canvas?: ComponentLike<"canvas"> },
		props = mergeProps({
			canvas: p => <canvas {...p} />,
		}, p),
	) => <props.canvas ref={r => refs.wave_ref = r} />

	MediaInput.RecordingDuration = (
		p: { span?: ComponentLike<"span"> },
		props = mergeProps({
			span: p => <span class=":c: right-14 font-mono mr3" {...p} />,
		}, p),
	) => <props.span innerText={formatVoiceRecordDuration(st.voice.now - recorder.started_at)} />

	return ctx
}
