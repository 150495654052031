import { batch, createContext, ParentProps, useContext } from "solid-js"
import { createMutable } from "solid-js/store"
import { useCache, api, type shapes, apiErrorHandled } from "#/lib/mod"

export function DealsContextProvider(props: ParentProps) {
	let cache = useCache()

	let ctx = createMutable({
		loading: false,

		rec_nums: [],
		rec_loading: false,

		pull, pullRecommendations,
	})

	async function pull() {
		ctx.loading = true

		let response = await api.req<{
			deals: shapes.MinDeal[]
			users: shapes.MinUser[]
		}>("/api/deals")

		if (apiErrorHandled(response, "Ошибка при загрузке дел")) {
			ctx.loading = false
			return
		}

		cache.updateBatch(response)
		cache.misc.addWatchedDeals(response.watched_deals)
		ctx.loading = false

		return response
	}

	async function pullRecommendations() {
		ctx.rec_loading = true

		let response = await api.req<{
			for_freelancer: shapes.MinVacancyDeal[]
			for_investor: shapes.MinBeggingDeal[]
			for_entrepreneur: shapes.MinInvestmentDeal[]
			other_by_interests: shapes.MinDeal[]
			tags: shapes.Tag[]
			users: shapes.MinUser[]
			projects: shapes.MinProject[]
		}>("/api/deals/recommendations")

		if (apiErrorHandled(response, "Ошибка при загрузке рекомендации")) {
			ctx.rec_loading = false
			return
		}

		let all_deals = [
			...response.for_freelancer,
			...response.for_investor,
			...response.for_entrepreneur,
			...response.other_by_interests,
			// @ts-ignore TODO
		].toSorted((a, b) => b.matched_tags_count - a.matched_tags_count)

		cache.updateBatch({
			deals: all_deals,
			tags: response.tags,
			users: response.users,
			projects: response.projects,
		})

		batch(() => {
			ctx.rec_nums = all_deals.map(x => x.num)
			ctx.rec_loading = false
		})
	}


	return Object.assign(<DealsContext.Provider value={ctx} children={props.children} />, { ctx })
}

let DealsContext = createContext<ReturnType<typeof DealsContextProvider>["ctx"]>()
export let useDeals = () => useContext(DealsContext)
