import { createComputed, createSignal, Show, type Component, type ComponentProps, type JSX } from "solid-js"

import { recompose, type ComponentLike } from "#/lib/rx/mod"

export const IMG_REMOVE_BORDER_SRC = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

type PictureProps =
	& {
		src: string
		Img?: ComponentLike<"img">
		Fallback?: Component | JSX.Element
	}
	& ComponentProps<"img">

export function Picture(props: PictureProps) {
	let { composed, uncomposed } = recompose(props, "src", "Img", "Fallback", "class", "classList")
	// @ts-ignore
	let { Img = (p => <img use:imageviewer {...p} />) } = uncomposed

	let [error, setError] = createSignal(false)

	createComputed(() => {
		uncomposed.src
		setError(false)
	})

	return (
		<Show
			when={!error() && uncomposed.src}
			fallback={typeof uncomposed.Fallback === "function" ? <uncomposed.Fallback /> : uncomposed.Fallback}
			children={
				<Img
					src={uncomposed.src}
					onLoad={[setError, false]}
					onError={[setError, true]}
					loading="lazy"
					{...composed}
					classList={{
						"ptr": true,
						[uncomposed.class]: !!uncomposed.class,
						...uncomposed.classList,
					}}
				/>
			}
		/>
	)
}
